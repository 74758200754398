import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
const CancelBid = () => {
    const web3 = useWeb3();
    const contractAddress = environment.MarketContract;
    const contract = getMarketPlaceContract(contractAddress, web3);
    const CancelBiding = useCallback(
        async (account, bidAmount,tokenId) => {
            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
                var result2 = parseInt(result) + 3000000000
                // // console.log("gasfun", typeof result2, result2)
                gasFunPrice = result2.toString()
            })

            try {
                const gas = await contract.methods
                    .cancelBid(bidAmount, tokenId)
                    .estimateGas({
                        from: account,  
                    })
                const details = await contract.methods
                    .cancelBid(bidAmount, tokenId)
                  .send({gas: gas,gasPrice: gasFunPrice,from: account})
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { CancelBiding: CancelBiding };
};
export default CancelBid;