import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getRegistryAddressContract } from "../../utils/contractHelpers";
import { getPublicResolverContract } from "../../utils/contractHelpers";
import { getSetContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import namehash from 'eth-ens-namehash'
import { formatsByName, formatsByCoinType } from '@ensdomains/address-encoder';
import { arrayify } from "ethers/lib/utils";
const DomainProfileCont = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const registrycontractAddress = environment.registryAddress;
    const contractRegistry = getRegistryAddressContract(registrycontractAddress, web3);
    const contractAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(contractAddress, web3);

    // // console.log('account of the day', account)
    const domainProfileFun = useCallback(
        async (node, data, bool) => {
            const name = namehash.hash(node)
            // // console.log('good hash', name)
            if (bool) {
                let dumArray = [];
                for (let i = 0; i < 4; i++) {
                    let cointype
                    if (i === 0) {
                        cointype = 0
                        // // console.log('btc data of contract', cointype, 'BTC')
                    } else if (i === 1) {
                        cointype = 60
                        // // console.log('btc data of contract', cointype, 'ETH')
                    } else if (i === 2) {
                        cointype = 2
                        // // console.log('btc data of contract', cointype, 'LTC')
                    } else if (i === 3) {
                        cointype = 3
                        // // console.log('btc data of contract', cointype, 'DOGE')
                    }
                    try {
                        // const decoderData = formatsByName[cointype].decoder(data[i].addr);
                        // let hexData = decoderData.toString('hex')
                        // const addr = formatsByCoinType[0].encoder(data);
                        const resContract = await contractRegistry.methods.resolver(name).call();
                        // // console.log('good registry', resContract)
                        if (resContract) {
                            const publicResolverContract = await getPublicResolverContract(resContract, web3);
                            // // console.log("hrtr is contrsct", publicResolverContract)
                            try {
                                const res = await publicResolverContract.methods.addr(name, cointype).call()
                                // // console.log('btc data of contract encoder simp', res)
                                if (res) {
                                    let hex = arrayify(res)
                                    const addr = formatsByCoinType[cointype].encoder(Buffer.from(hex));
                                    dumArray.push(addr)
                                    // // console.log('btc data of contract encoder succe', res)
                                } else {
                                    dumArray.push('')
                                }
                            } catch (error) {
                                // // console.log('btc data of contract encoder err', error)
                            }
                        }
                    } catch (error) {
                        // // console.log('btc data of contract encoder bar', error);
                    }
                    // 1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa
                }
                // // console.log('btc data of contract dumarray', dumArray)
                return dumArray;
            }
            else {
                if (name) {
                    // // console.log('btc data of contract', data[0].addr, data[0].addr.toString('hex'))
                    var gasFunPrice;
                    web3.eth.getGasPrice().then((result) => {
                        var result2 = parseInt(result) + 3000000000
                        // // console.log("gasfun", typeof result2, result2)
                        gasFunPrice = result2.toString()
                    })
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].addr !== '') {
                            try {
                                let cointype
                                if (data[i].coinType === 0) {
                                    cointype = 'BTC'
                                    // // console.log('btc data of contract', cointype, 'BTC')
                                } else if (data[i].coinType === 60) {
                                    cointype = 'ETH'
                                    // // console.log('btc data of contract', cointype, 'ETH')
                                } else if (data[i].coinType === 2) {
                                    cointype = 'LTC'
                                    // // console.log('btc data of contract', cointype, 'LTC')
                                } else if (data[i].coinType === 3) {
                                    cointype = 'DOGE'
                                    // // console.log('btc data of contract', cointype, 'DOGE')
                                }
                                const decoderData = formatsByName[cointype].decoder(data[i].addr);
                                let hexData = decoderData.toString('hex')
                                // const addr = formatsByCoinType[0].encoder(data);
                                // // console.log('btc data of contract encoder', decoderData, decoderData.toString('hex'));
                                const resContract = await contractRegistry.methods.resolver(name).call();
                                // console.log('good registry', resContract)
                                if (resContract) {
                                    const publicResolverContract = await getPublicResolverContract(resContract, web3);
                                    // console.log("hrtr is contrsct", publicResolverContract)
                                    try {
                                        const gas = await publicResolverContract.methods.setAddr(name, data[i].coinType, decoderData).estimateGas({ from: account, })
                                        const res = await publicResolverContract.methods.setAddr(name, data[i].coinType, decoderData).send({ from: account, gas: gas, gasPrice: gasFunPrice, })
                                        // // console.log('btc data of contract encoder succe', res)
                                    } catch (error) {
                                        // // console.log('btc data of contract encoder err', error)
                                        throw error
                                    }
                                }
                            } catch (error) {
                                // // console.log('btc data of contract encoder', error);
                                throw error
                            }

                        }
                    }
                }
            }
        },
        [contract, account]
    );
    return { domainProfileFun: domainProfileFun };
};
export default DomainProfileCont;