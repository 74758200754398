import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

export const BalanceOf = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.tomiToken;
    const contract = gettomiTokenContract(contractAddress, web3);
    const balanceOfHook = useCallback(async (account, contractAdd) => {
        try {
            const details = await contract.methods.balanceOf(account)
                .call({
                    from: account,
                })
          let  details2 = web3.utils.fromWei(details?.toString(), 'ether')
            return details2

        } catch (error) {
            console.log('error of the BalanceOf---contract-error', error)
            throw error;

        }
    }, [contract, account]);

    return { balanceOfHook: balanceOfHook };
};

export default BalanceOf;
