import React, { useEffect, useState } from 'react'
function SellDomain() {
    
    return (
        <section className="fifth">
            <div className="headingfifth">
                <h6>SELLING OPTIONS</h6>
            </div>
            <div className="bottom-content">
                {/* <div className="inner-card">
                              <div className="left">
                                <h6>List for Sale</h6>
                                <h5>
                                  {" "}
                                  <span>THE EASIEST OPTION </span>- List your
                                  domain for sale in minutes{" "}
                                </h5>
                                <a className="list-btn">List for Sale</a>
                              </div>
                              <div className="right">
                                <img
                                  src="\assets\profile\selling-img.png"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div> */}
                <div className="inner-card">
                    <div className="left">
                        <h6>Sell on Opensea</h6>
                        <h5>
                            {" "}
                            List your domain for sale on the largest
                            marketplace for NFTs
                        </h5>
                        <a href='https://opensea.io/' className="opensea" target='_blank'>
                            List for Sale on Opensea
                        </a>
                    </div>
                    <div className="right">
                        <img
                            src="\assets\profile\opensea.png"
                            alt="img"
                            className="img-fluid"
                        />
                    </div>
                </div>
                <div className="last-inline-text">
                    <img
                        src="\assets\profile\info-circle.svg"
                        alt="img"
                        className="img-fluid mr-2"
                    />
                    <p>
                        You need to connect to Opensea the wallet where
                        this domain is stored with address: 0x0F4Dc5 to
                        list for sale this domain.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default SellDomain