import React, { useState } from 'react';

const AutoSu = () => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = async (inputValue) => {
    const phrase = encodeURIComponent(inputValue);
    const proxyUrl = 'http://localhost:8080'; // Replace with your CORS-anywhere server URL
    const url = `${proxyUrl}/https://unstoppabledomains.com/api/domain/search/ai-suggestions?extension=All&phrase=${phrase}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setSuggestions(data.results.map((result) => result.domain));
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setSuggestions([]); // Clear suggestions when the input value changes
  };

  const handleButtonClick = () => {
    if (value.length > 0) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <div>
      <input type="text" value={value} onChange={handleInputChange} />
      <button onClick={handleButtonClick}>Get Suggestions</button>
      <ul>
        {suggestions.map((suggestion) => (
          <li key={suggestion}>{suggestion}</li>
        ))}
      </ul>
    </div>
  );
};

export default AutoSu;