import React from 'react'

function Email() {
  return (
    <div className="email">
    <h4>Email Messaging</h4>
    <p>
      This allows you to send and receive messages with
      your domain name without having your private email
      address visible to receiveer or sender. Your private
      address is always protected.{" "}
      <a href="">Learn More</a>
    </p>
    <div className="switch-elem">
      <label class="toggle">
        <input class="toggle-checkbox" type="checkbox" />
        <div class="toggle-switch"></div>
        <span class="toggle-label">Enable <span className="bold-color">aaron.xud.me</span> email address</span>
      </label>
    </div>
  </div>
  )
}

export default Email