import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DomainProfile from '../../hooks/dataSenders/subdomain'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import ChildOfId from '../../hooks/dataFetchers/myDomains'
import { Link, useHistory } from 'react-router-dom'
import Environment from '../../utils/Environment'
function SubDomain({ UrlData, setLoader, tokenId, setFirst, first }) {
    const [inpoData, setInputData] = useState('')
    const { account } = useWeb3React()
    const { domainProfileFun } = DomainProfile()
    const [subDomains, setSubDomains] = useState()
    const { Mainfunct } = ChildOfId();
    const history = useHistory()
    // // console.log('token id ', tokenId)
    const getNftsdata = async () => {
        if (account) {
            setLoader(true)
            var data = JSON.stringify({
                query: `{
                  account(id: "${account && account.toLocaleLowerCase()}") {
                    registrations {
                      expiryDate
                      domain {
                        id
                        labelName
                        labelhash
                        name
                        isMigrated
                        parent {
                          name
                          __typename
                          id
                        }
                        __typename
                        subdomains {
                          id
                          name
                          labelName
                          labelhash
                        }
                      }
                      __typename
                    }
                  }
                }`,
                variables: {},
            });

            var config = {
                method: "post",
                url: Environment.dnsGraph,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    setLoader(true);
                    // dispatch(userNfts(response.data.data.domains));
                    // setNft(response.data.data.domains);
                    // checkCondition(response.data.data.domains);
                    // getStakedNfts(response.data.data.domains);
                    //   setMorData(response?.data?.data?.account?.registrations)
                    //   myDomainsFun(response?.data?.data?.account?.registrations)
                    //   setActualDomainArr(response?.data?.data?.account?.registrations)
                    setSubDomains(response?.data?.data?.account?.registrations)
                    // // console.log('result of the sub Domains---->', response?.data?.data?.account?.registrations)
                    setLoader(false);
                })
                .catch(function (error) {
                    setLoader(false);
                    // setNft([]);
                    // // // console.log('result of the myDomains',error);
                });
        }
    };
    const confirCryptoChanges = async () => {
        // console.log('subdomain account', UrlData, inpoData, account, tokenId)
        if (inpoData && account) {
            try {
                setLoader(true)
                const res = await domainProfileFun(UrlData, inpoData, account, tokenId)
                toast.success('SubDomain set Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                // // console.log('domainProfile contract data succ', res)
                toast.info('Subdomain will take time to show', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setLoader(false)
                getNftsdata()
                setTimeout(() => {
                    getNftsdata()
                }, 7000);
            } catch (error) {
                // // console.log('subdomain error', error)
                toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
                setLoader(false)
            }
        } else {
            toast.error('Account or input data is missing!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    }
    const myDomainsFun = async (rawArray) => {
        let dumArray = []
        if (subDomains?.length > 0) {

            // for (let i = 0; i < ?; i++) {
            try {
                const res = await Mainfunct("0x3b8f5f02f77cf61f8719abbdd42ea10aabd152d0054ab020c1cac0d439463b00")
                dumArray.push(res)
            } catch (error) {
                // // console.log('subjdhfbcndomain', error)
            }

            // }
        }
        // // console.log('subjdhfbcndomain', dumArray)
        // rawArray.forEach(async (item, index, arr) => {
        //   // // console.log('result of the myDomains ==============>no---', index)
        //   arr[index] = await Mainfunct(item?.domain?.token_id)
        // })
        // setActualDomainArr(rawArray)
    }
    const manageSub = (item) => {
        setFirst(!first)
        history.push(`/domainProfile/${JSON.stringify(item)}`)
        history.go(0)
    }
    useEffect(() => {
        getNftsdata()
        myDomainsFun()
    }, [account])
    return (
        <div className="website subDomain">
            <div className="top">
                <h5>Sub Domain</h5>
            </div>
            <div className="option-field subInner d-flex align-items-center">
                <input onChange={(e) => setInputData(e.target.value)} name='IpfsHash' type="text" placeholder="Type in a label for subdomain" />
                <div className="bottom-btn mt-0 pt-0">
                    <button onClick={confirCryptoChanges} className='ml-2 pl-4 pr-4'>Save</button>
                    <button className='ml-2'>Cancel</button>
                </div>
            </div>
            {/* {// console.log('result of the sub Domains----> map', subDomains,UrlData)} */}
            {subDomains?.map((item) => {
                return (
                    item?.domain?.name === UrlData && item?.domain?.subdomains?.map((item) => {
                        return (
                            <div className="domainDelete align-items-center">
                                <div className="imgName d-flex align-items-center">
                                    <img className='img-fluid' src="\searchResults\polyInfinite.svg" alt="" style={{ width: '30px' }} />
                                    <h6 className=''>{item?.name?.slice(0, 33)}</h6>
                                </div>
                                {/* <Link  to={}> */}
                                <button onClick={() => manageSub(item)} className=" img-btn-outline">
                                    <img src="\searchResults\setting.svg" alt="" />
                                    Manage
                                </button>
                                {/* </Link> */}
                                {/* <img src="\assets\trash.svg" alt="" /> */}
                            </div>
                        )
                    }))
            })}

            {/* <div className="domainDelete">
                <div className="imgName d-flex">
                    <img className='img-fluid' src="\assets\box.svg" alt="" />
                    <h6>rana.salman123.eth</h6>
                </div>

                <img src="\assets\trash.svg" alt="" />
            </div> */}
        </div>
    )
}

export default SubDomain