import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getPublicResolverContract } from "../../utils/contractHelpers";
import { getApproveContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import Environment from "../../utils/Environment";
import namehash from 'eth-ens-namehash'
const DomainMinting = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const tokenAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(tokenAddress, web3);
    const tokenAddressResolver = environment.resolver;
    const contractResolver = getPublicResolverContract(tokenAddressResolver, web3);
    const DAYS = 24 * 60 * 60
    const REGISTRATION_TIME = 28 * DAYS
    const secret = '0x0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF'
    const domainMintingMethod = useCallback(
        async (label, bool, typeOD) => {
            // // console.log('typeOD of of',typeOD)
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                // console.log('labeeel',typeof label)
                let nameHash = namehash.hash(label + `.${typeOD}`)
                let tldHash = namehash.hash(`${typeOD}`)
                const resResolver = await contractResolver.methods.setName(nameHash, label + `.${typeOD}`).encodeABI();
                // console.log('resolverrrrrrrrrr', label, REGISTRATION_TIME, tldHash)
                console.log('resolverrrrrrrrrr', resResolver, label + `.${typeOD}`)
                if (resResolver) {
                    const resTime = await contract.methods.rentPrice(label, REGISTRATION_TIME, tldHash).call();
                    // console.log('resolverrrrrrrrrr', label, REGISTRATION_TIME, tldHash)
                    let resTimeSum = parseInt(resTime.base) + parseInt(resTime.premium)
                    // // console.log('res of the delay', typeof label, account, resTimeSum, secret, Environment.resolver)
                    const res = await contract.methods.makeCommitment({ name: label, tld: typeOD }, account, REGISTRATION_TIME, secret, Environment.resolver, [resResolver], false, 0).call()
                    // console.log('res of the delay makeCommitment', res)
                    if (res && bool) {
                        const gas = await contract.methods.commit(res).estimateGas({
                            from: account,
                        })
                        console.log('estimalte gass', gas, gasFunPrice)
                        const res2 = await contract.methods.commit(res).send({
                            from: account,
                            gas: gas,
                            gasPrice: gasFunPrice,
                        })
                        // console.log('res of the delay commit', res2)
                        return res2;
                    } else if (!bool) {
                        if (!bool) {

                            // // // console.log('1111113333333333333', web3.eth.getGasPrice())
                            const gas = await contract.methods.register({ name: label, tld: typeOD }, account, REGISTRATION_TIME, secret, Environment.resolver, [resResolver], false, 0, 0).estimateGas({
                                from: account
                            })
                            const res3 = await contract.methods.register({ name: label, tld: typeOD }, account, REGISTRATION_TIME, secret, Environment.resolver, [resResolver], false, 0, 0).send({
                                from: account,
                                gas: gas,
                                gasPrice: gasFunPrice
                            }).on('transactionHash', (tx) => {
                                return tx.transactionHash;
                            })
                            return res3;
                        }
                    }
                    // // console.log('res of the delay', res)
                }


            } catch (error) {
                // console.log('res of the delay', error)
                throw error
            }
        },
        [contract, account]
    );
    return { domainMintingMethod: domainMintingMethod };
};
export default DomainMinting;