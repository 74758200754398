import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/Navbar'
import "./commission.scss"
import DataTable from './DataTable'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Environment from '../../utils/Environment'
import axios from 'axios'
import Loader from '../../hooks/loader'
import CashBacks from '../../hooks/dataFetchers/cashback'
import useWeb3 from '../../hooks/useWeb3'
import MinterMultipleCashBackReward from '../../hooks/dataSenders/minterMultipleCashBackReward'
import Commision from '../../hooks/dataFetchers/commision'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import { API_URL } from '../../ApiUrl'
const CashBack = ({ disN, setLoader }) => {
  const { account } = useWeb3React()
  const web3 = useWeb3();
  const [commision, setCommision] = useState(0)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [mintedData, setMintedData] = useState()
  const [additionalDataArr, setAdditionalDataArr] = useState([])
  const [availableCommision, setAvaiCommision] = useState(0)
  const [cashbacks, setCashBacks] = useState([])
  const [apiStatus, setApiCallStatus] = useState(false)
  const [multiIds, setMultiIds] = useState([])
  const { ClaimMultipleCashBack } = MinterMultipleCashBackReward()
  const [LastCommisionClaimed, setLastCommisionClaimed] = useState(0)
  const [claimIds, setClaimIds] = useState([]);
  const { currCashBack } = CashBacks()

  // console.log('ggggggggggggg', availableCommision)

  //   setLoader(true)
  //   var config = {
  //     method: "get",
  //     url: `https://deep-index.moralis.io/api/v2/${account}/nft/${Environment.partnerNFT}?chain=polygon&format=decimal`,
  //     headers: {
  //       "x-api-key":
  //         "8PhWxNHdk3lT8nBPqwYO93tpqCDqHPUbGrPTxxEWcEk3yHaMVyDs5qvVQ7bLEsav",
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       let minted = response.data.result;
  //       let partnerNftsIds = minted?.map((item) => parseInt(item?.token_id))
  //       getAdditionalData(partnerNftsIds)
  //       getTotalClaimed(partnerNftsIds)

  //       console.log('partnerNft', minted, partnerNftsIds)
  //       setMintedData(minted)
  //       setLoader(false)
  //     })
  //     .catch(function (error) {
  //       console.log('partnerNft', error)
  //       // // console.log(error);
  //       setLoader(false)
  //     });
  // };
  const getMinteddata = async (ids) => {
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
        query MyQuery {
          auctionCreateds(where: {minter:  "${account?.toLowerCase()}"}) {
            tld
            label
            labelhash
            isClaimed
            expiryTime
            minter
            partnerId
            startTime
            tokenId
          }
        }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // console.log('cashback', response)
          let claimedCommison = response?.data?.data?.auctionCreateds
          setCashBacks(claimedCommison)
          var dumArr = [];
          for (var i = 0; i < claimedCommison.length; i++) {
            dumArr.push(claimedCommison[i]?.tokenId)
          }
          setMultiIds(dumArr)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getTotalCommison = async () => {
    var totalMintAmount = 0;
    let dumArr = []
    for (var i = 0; i < cashbacks?.length; i++) {
      try {
        const commision = await currCashBack(cashbacks[i]?.tokenId)
        if (commision > 0) {
          dumArr.push(parseInt(cashbacks[i]?.tokenId))
        }
        var mintAmount = parseFloat(commision);
        // console.log('first', commision)
      } catch (error) {
        var mintAmount = parseFloat(0);
      }
      totalMintAmount += mintAmount;
    }
    setAvaiCommision(totalMintAmount)
  }

  let toastDesign = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }

  const claimCommisionValue = async () => {
    if (claimIds?.length > 0 || cashbacks?.length > 0) {
      try {
        let tok = localStorage.getItem("accessToken");
        let idos = [];
        if (claimIds?.length > 0) {
          idos = claimIds;
        } else {
          for (let i of cashbacks) {
            const commision = await currCashBack(i?.tokenId);
            if (parseFloat(commision) > 0) {
              idos.push(i?.tokenId)
            }
          }
        }
        if (idos?.length > 0) {
          let data = {
            tokenIds: idos,
            walletAddress: account?.toLowerCase()
          }
          var config = {
            method: "post",
            url: `${API_URL}/compensation/get-cashback-claim`,
            data: data,
            headers: {
              authorization: `Bearer ` + tok
            }
          };
          axios(config)
            .then(async function (response) {
              setLoader(true)
              try {
                let tokenIds = [];
                let v = [];
                let r = [];
                let s = [];
                for (let i of response?.data?.data) {
                  tokenIds.push(i.tokenId);
                  v.push(i.v);
                  r.push(i.r);
                  s.push(i.s);
                }
                try {
                  const claimed = await ClaimMultipleCashBack(tokenIds, v, r, s, account)
                  handleShow()
                  setClaimIds([]);
                  setApiCallStatus(!apiStatus)
                  setLoader(false)
                } catch (err) {
                  setLoader(false)
                  console.log("aaaaa", err);
                  handleShow1()
                }
              } catch (err) {
                handleShow1()
                // console.log('first', error)
                setLoader(false)
              }
            }).catch(function (error) {
              handleShow1()
              // console.log('first', error)
              setLoader(false)
            });
        }
      } catch (error) {
        handleShow1()
        // console.log('first', error)
        setLoader(false)
      }
    }
    else {
      toast.error("You don't have any referals to claim", toastDesign)
    }
  }
  useEffect(() => {
    if (cashbacks) {
      getTotalCommison()
    }
  }, [cashbacks, apiStatus])
  useEffect(() => {
    if (account) {
      getMinteddata()
    }
  }, [account, apiStatus])
  return (
    <>
      {disN || <Navbar />}
      <section className="mycommission-section">
        <div className="custom-container">
          <div className="bottom-part">
            <div className="upper-area">
              <h6>My Compensation History</h6>
              <button disabled={availableCommision === 0} className={availableCommision === 0 ? 'btn-claim btnDis px-3' : ' btn-claim'} onClick={claimCommisionValue}>{availableCommision === 0 ? 'All Claimed' : 'Claim All'}</button>
              {/* <button className='btn-claim' onClick={claimCommisionValue}>Claim All</button> */}
            </div>
            <DataTable setClaimIds={setClaimIds} claimIds={claimIds} cashbacks={cashbacks} setLoader={setLoader} setApiCallStatus={setApiCallStatus} apiStatus={apiStatus} />
          </div>
        </div>
      </section>
      <Modal className='success-modal' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\success2.svg" alt="img" className='img-fluid' />
            <h6> Claimed Successfully</h6>
          </div>
          <button onClick={handleClose} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>

      <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
            <h6>Failed to claim commission. try again</h6>
          </div>
          <button onClick={handleClose1} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CashBack
