import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import { getuniSwapRouter } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import axios from "axios";

export const GetTomiPrice = () => {
    const web3 = useWeb3();
    const tokenAddress = environment.uniSwapRouter;
    const contract = getuniSwapRouter(tokenAddress, web3);
    const TomiPrice = useCallback(async (account) => {
        try {
            const ethVAl = web3.utils.toWei('1', 'ether')
            const path = [Environment.oTomiToken, Environment.usdt]
            const amounts = await contract.methods.getAmountsOut(ethVAl, path).call();
            const outputAmount = web3.utils.fromWei(amounts[1], 'wei');
            const price = outputAmount / Math.pow(10, 6);
            const totalWorth = 100;
            const totalTokens = totalWorth / price;
            return totalTokens;
        } catch (error) {
            console.log("balance", error)
            throw error
        }

    }, [contract]);
    // const TomiPrice = useCallback(async (account) => {
    //     try {
    //         //      const approved = await contract.methods.balanceOf(Environment.StackContract).call();
    //         // let a = {}
    //         // // let balance = web3.utils.fromWei(approved, "ether")
    //         // let balance = parseInt(approved) / 1000000000000000000
    //         // a.tomi = balance
    //         let b = await axios
    //             .get("https://api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false")
    //         // let c = (balance * b.data.tominet.usd).toFixed(0)
    //         // a.dollar = c
    //         const tokenValue = b.data.tominet.usd;
    //         const totalWorth = 100;

    //         const totalTokens = totalWorth / tokenValue;
    //         const outputAmount = web3.utils.fromWei('5124998', 'wei');
    //         const price = outputAmount / Math.pow(10, 18);
    //         console.log('Total Tomi Tokens:- ppp', price, price.toFixed(18));
    //         return totalTokens;
    //     } catch (error) {
    //         console.log("balance", error)
    //     }

    // }, [contract]);

    return { TomiPrice: TomiPrice };
};

export default GetTomiPrice;