import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
const MinterMultipleCashBackReward = () => {
    const web3 = useWeb3();
    const contractAddress = environment.market;
    const contract = getMarketPlaceContract(contractAddress, web3);
    const ClaimMultipleCashBack = useCallback(
        async (tokenId, v, r, s, account) => {
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                const gas = await contract.methods
                    .minterCashbackClaimMultiple(tokenId, v, r, s).estimateGas({ from: account });
                const details = await contract.methods
                    .minterCashbackClaimMultiple(tokenId, v, r, s)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasFunPrice
                    })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { ClaimMultipleCashBack: ClaimMultipleCashBack };
};
export default MinterMultipleCashBackReward;