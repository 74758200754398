import React from 'react'
import './cryptocurrency.scss'
function Cryptocurrency() {
    return (
        <section className="creditCard-main cryptoMain-main">
            <div className="row">
                <div className="col-md-8 pl-md-0">
                    <div className="cryptoHeading text-center">
                        <h1>Pay by Cryptocurrency</h1>
                        <p>When you start this transaction, store credits will be deducted from your account. If you do not complete the transaction or cancel the payment, the store credits will be restored to your account.</p>
                        <div className="row">
                            <div className="col-md-6 p-0 mx-auto">
                                <button className="img-btn w-100">Start Transaction</button>
                            </div>
                        </div>
                    </div>
                    <div className="accord-Parent text-center">
                        <p className='parad'>Select cryptocurrency and send it to the address below</p>
                        <div className='text-left accrodinDiv' id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span><img src="\chekout\bitcoin.png" alt="" /> <p>Bitcoin</p></span>
                                            <span><p>0.1008491464</p><img src="\searchResults\arrowDown.png" alt="" /></span>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <hr className='m-0 p-0' style={{ borderTop: '1px solid #EFEFEF' }} />
                                        <p className='label' htmlFor="">$260.00</p>
                                        <div className="copyInput">
                                            <input type="text" name="" placeholder='0.01406335' id="" />
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <p className='label' htmlFor="">Bitcoin Add</p>
                                        <div className="copyInput">
                                            <div className="copyInput">
                                                <input type="text" name="" placeholder='3nvrodhfuehfofiehdfscuoeihfcuod' id="" />
                                                <div className="absltImgcopy">
                                                    <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <div className="qrDiv">
                                            <img src="\chekout\qrCode.svg" className='img-fluid' alt="" />
                                            <p>JN2QYOZ3LA2BU3RU</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <span><img src="\chekout\bitcoinGreen.png" alt="" /> <p>Bitcoin Cash</p></span>
                                            <span><p>0.1008491464</p><img src="\searchResults\arrowDown.png" alt="" /></span>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        <hr className='m-0 p-0' style={{ borderTop: '1px solid #EFEFEF' }} />
                                        <p className='label' htmlFor="">$260.00</p>
                                        <div className="copyInput">
                                            <input type="text" name="" placeholder='0.01406335' id="" />
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <p className='label' htmlFor="">Bitcoin Add</p>
                                        <div className="copyInput">
                                            <div className="copyInput">
                                                <input type="text" name="" placeholder='3nvrodhfuehfofiehdfscuoeihfcuod' id="" />
                                                <div className="absltImgcopy">
                                                    <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <div className="qrDiv">
                                            <img src="\chekout\qrCode.svg" className='img-fluid' alt="" />
                                            <p>JN2QYOZ3LA2BU3RU</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                            <span><img src="\chekout\dai.png" alt="" /> <p>Dai (ERC-20 only)</p></span>
                                            <span><p>0.1008491464</p><img src="\searchResults\arrowDown.png" alt="" /></span>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        <hr className='m-0 p-0' style={{ borderTop: '1px solid #EFEFEF' }} />
                                        <p className='label' htmlFor="">$260.00</p>
                                        <div className="copyInput">
                                            <input type="text" name="" placeholder='0.01406335' id="" />
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <p className='label' htmlFor="">Bitcoin Add</p>
                                        <div className="copyInput">
                                            <div className="copyInput">
                                                <input type="text" name="" placeholder='3nvrodhfuehfofiehdfscuoeihfcuod' id="" />
                                                <div className="absltImgcopy">
                                                    <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <div className="qrDiv">
                                            <img src="\chekout\qrCode.svg" className='img-fluid' alt="" />
                                            <p>JN2QYOZ3LA2BU3RU</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            <span><img src="\chekout\lai.png" alt="" /> <p>Litecoin</p></span>
                                            <span><p>0.1008491464</p><img src="\searchResults\arrowDown.png" alt="" /></span>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                    <div class="card-body">
                                        <hr className='m-0 p-0' style={{ borderTop: '1px solid #EFEFEF' }} />
                                        <p className='label' htmlFor="">$260.00</p>
                                        <div className="copyInput">
                                            <input type="text" name="" placeholder='0.01406335' id="" />
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <p className='label' htmlFor="">Bitcoin Add</p>
                                        <div className="copyInput">
                                            <div className="copyInput">
                                                <input type="text" name="" placeholder='3nvrodhfuehfofiehdfscuoeihfcuod' id="" />
                                                <div className="absltImgcopy">
                                                    <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <div className="qrDiv">
                                            <img src="\chekout\qrCode.svg" className='img-fluid' alt="" />
                                            <p>JN2QYOZ3LA2BU3RU</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfive">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                                            <span><img src="\chekout\bitcoin.png" alt="" /> <p>USDC (ERC-20 only) </p></span>
                                            <span><p>0.1008491464</p><img src="\searchResults\arrowDown.png" alt="" /></span>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapsefive" class="collapse" aria-labelledby="headingfive" data-parent="#accordion">
                                    <div class="card-body">
                                        <hr className='m-0 p-0' style={{ borderTop: '1px solid #EFEFEF' }} />
                                        <p className='label' htmlFor="">$260.00</p>
                                        <div className="copyInput">
                                            <input type="text" name="" placeholder='0.01406335' id="" />
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <p className='label' htmlFor="">Bitcoin Add</p>
                                        <div className="copyInput">
                                            <div className="copyInput">
                                                <input type="text" name="" placeholder='3nvrodhfuehfofiehdfscuoeihfcuod' id="" />
                                                <div className="absltImgcopy">
                                                    <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                    <p>Copy</p>
                                                </div>
                                            </div>
                                            <div className="absltImgcopy">
                                                <img src="\chekout\copy.svg" className='img-fluid' alt="" />
                                                <p>Copy</p>
                                            </div>
                                        </div>
                                        <div className="qrDiv">
                                            <img src="\chekout\qrCode.svg" className='img-fluid' alt="" />
                                            <p>JN2QYOZ3LA2BU3RU</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Collapsible Group Item #3
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-md-0 mt-5">
                    <div className="orderSummary timer mb-2">
                        <div className="domain-price m-0 p-0 ">
                            <span>
                                <h6>Timer</h6>
                            </span>
                            <h4 className=''>23 : 59 : 59 : 59 </h4>
                        </div>
                    </div>
                    <div className="orderSummary">
                        <div className="heading">
                            <h3>Order Summary</h3>
                            <p>See order details</p>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <div>
                                <h6>Total Due</h6>
                                <h4>$260.00</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cryptocurrency