import React, { useEffect, useState } from 'react'
import "./refferal.scss"
import HeaderNew from '../LandingNew/HeaderNew/HeaderNew'
import { toast } from 'react-toastify'
import Loader from '../../hooks/loader'
import { API_URL } from '../../ApiUrl'
import axios from 'axios'
import Navbar from '../landing/header/Navbar'


const Settings = () => {
    const [nickName, setNickName] = useState()
    const [loader, setLoader] = useState(false);
    const user =localStorage.getItem('userName') ||  localStorage.getItem('user')
    const [error, setError] = useState(false)
    const userObj = JSON.parse(user)
    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    const checkAvail = async (e) => {
        setNickName(e.target.value)
        var data = {
          nickName: e.target.value
        }
        var config = {
          method: "get",
          url: `${API_URL}/customers/nickname/is-available?nickName=${e.target.value}`,
          data: data,
        };
        axios(config)
          .then(function (response) {
            setError(false)
            setNickName(e.target.value)
          }).catch(function (error) {
            toast.error()
            if (error?.response?.data?.statusCode === 409) {
            //   setNickName('')
              setError(true)
            }
            // console.log('sdfasdf',error?.response?.data?.statusCode)/ 
    
    
          });
      }
    let nameUpdate = async () => {
        if(nickName){
        setLoader(true)
        let tok = localStorage.getItem("accessToken");
        let data = {
            nickName: nickName
        }
        var config = {
            method: "patch",
            url: `${API_URL}/customers/profile`,
            data: data,
            headers: {
                authorization: `Bearer ` + tok
            }
        };
        axios(config)
            .then(function (response) {
                setLoader(false)
                toast.success(response?.data?.message, toastDesign);
                localStorage.setItem('userName', JSON.stringify(response?.data?.data))
            }).catch(function (error) {
                setLoader(false)
                //   if (error.code == 401) {
                //     refreshToken()
                //   }
                toast.error(error?.response?.data?.message, toastDesign)
            });
        }else{
            toast.info('Name Input is Empty', toastDesign)
        }
    }
    useEffect(() => {
        setNickName(userObj?.nickName)
    }, [userObj?.nickName])
    
    return (
        <>
        {loader && <Loader />}
            <Navbar tNav={'tNav'}/>
            <section className="refferal-section setting-secti">
                {/* <img src="\assets\bg-refferal.png" alt="img" className='img-fluid bg-refferal' /> */}
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h2>Settings</h2>
                        <p>Edit your nickname</p>
                    </div>
                    <div className="inner-box1">
                        <div className="left">
                            <div className="bottom-box">
                                <h6>Your Nickname</h6>
                                <div className="option-field">
                                    <input className={error && "inpuDisable"} value={nickName} onChange={checkAvail} type="text" placeholder='Nick Name' />
                                    {error && <p className="errorTxt">This nickname is already taken. Try a different one</p>}
                                </div>
                                <button disabled={error} onClick={nameUpdate} className={error ? "btn-save btnDisable" : "btn-save"}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Settings
