import React, { useState } from 'react'
import "./dsearchResult.scss"
import ProgressBar from 'react-bootstrap/ProgressBar';
import Navbar from '../landing/header/Navbar';
import "./domainSearch.scss";
import { useParams, useHistory } from 'react-router-dom'
import environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from '../../hooks/useWeb3';
import { gettomiTokenContract } from "../../utils/contractHelpers";
import DomainMinting from '../../hooks/dataSenders/mintDomain';
import DomainMintingPrice from '../../hooks/dataSenders/mintPrice';
import { toast } from 'react-toastify';
import Approve from '../../hooks/dataSenders/approveTomi'
import Countdown from 'react-countdown';
import { Modal, Row, Toast, ToastContainer } from 'react-bootstrap';
import Loader from '../../hooks/loader';
import namehash from 'eth-ens-namehash'
import Allowance from '../../hooks/dataFetchers/allowance'
import GetTomiPrice from '../../hooks/dataFetchers/tomiPriceGet'
import { useEffect } from 'react';
import Environment from '../../utils/Environment';
import BalanceOf from '../../hooks/dataFetchers/balanceOf'
import ChildOfId from '../../hooks/dataFetchers/childIdOf'
import {TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
const ProgressBarComp = () => {
  const [loader, setLoader] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const { domainMintingMethod } = DomainMinting()
  const { domainMintingPriceMethod } = DomainMintingPrice()
  const { UserApprove } = Approve()
  const { checkAllowance } = Allowance()
  const { TomiPrice } = GetTomiPrice()
  let { account } = useWeb3React()
  const web3 = useWeb3();
  const [showSuccess, setSuccess] = useState(false)
  const [allowanceValue, setAllowanceValue] = useState(0)
  const [showError, setError] = useState(false)
  const [showWarning, setWarning] = useState(false)
  const [timerTime, setTimerTime] = useState(0)
  const [mintPrice, setMintPrice] = useState()
  const [tPrice, setTPrice] = useState() // dummy value
  const [step, setStep] = useState(0)
  const [stepp, setStepp] = useState(0)
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  let { type, inpuVal } = useParams();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const contractAddress = environment.tomiToken;
  const [balance, setBalance] = useState(true)
  const { balanceOfHook } = BalanceOf()
  const [avail, setAvail] = useState(true)
  const [copied, setCopied] = useState(false)
  const { Mainfunct } = ChildOfId();
  const allowanceofAccount = async () => {
    let res = await balanceOfHook(account)
    console.log("ressss", res, mintPrice)
    setBalance(res)

    if (parseFloat(res) <= parseFloat(mintPrice)) {
      handleShow2()
      setShow(false)
    } else {
    }
  }
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  // console.log('jdfasdfl', balance)
  let date = Date.now()
  // // console.log('dfjaojfo', date)
  let coloredToast = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }
  const getTotalStakers = async () => {
    try {
      setLoader(true)
      let result2 = await TomiPrice()
      setTPrice(result2?.toFixed(2));
      setLoader(false)
    } catch (error) {
      setTPrice(30);
      setLoader(false)
    }
  };
  const mintdom = async () => {
    try {
      try {
        setShow(false)
        let response;
        if (true) {
          setLoader2(true)
          try {
            let allowanceRes = await checkAllowance(account, Environment.ethRegistrar)
            allowanceRes = web3.utils.fromWei(allowanceRes.toString(), 'ether')
            // console.log('asldfalsdf', allowanceRes, tPrice)
            if (parseFloat(allowanceRes) >= parseFloat(mintPrice)) {
              response = true
              setLoader2(false)
            } else {
              response = await UserApprove()
              setLoader2(false)
              toast.success('Approval Successfull', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              })
            }
          } catch (error) {
            setLoader2(false)
            toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0] || error?.message?.split(':')[1], coloredToast)
          }
        }
        if (response) {
          setStepp(5)
          setLoader(true)
          try {
            const res = await domainMintingMethod(inpuVal, true, type)
            if (res) {
              setStepp(35)
              setStep(50)
              setTimerTime(Date.now())
              localStorage.setItem('dName', inpuVal + type)
              localStorage.setItem('aToken', account)
              localStorage.setItem('nowTime', Date.now())
              await new Promise(resolve => {
                setTimeout(resolve, 30000);
              });
              setTimerTime(0)
              setLoader(false)
              setStep(99)
            }
            setLoader(false)
          } catch (error) {
            // console.log('asldfalsdf', error)
            toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0] || error?.message?.split(':')[1], coloredToast)
            // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
            setLoader(false)
          }

        } else {
          return
        }
      } catch (error) {
        toast.error('Minting Failed', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
        setLoader2(false)
        setStepp(0)
        setLoader(false)
        setError(true)
        setStep(0)
      }

    } catch (error) {
      setStepp(0)
      setLoader(false)
      setError(true)
      setStep(0)
    }

  }
  const mintdomFinal = async () => {
    const res = await Mainfunct(inpuVal, type?.toLocaleLowerCase())
    setAvail(res)
    if (res) {
      try {
        setStepp(71)
        setStep(99)
        setLoader(true)
        const res = await domainMintingMethod(inpuVal, false, type)
        // setWarning(true)
        setLoader(false)
        setStep(100)

        let data = res
        data = data.events.NameRegistered.returnValues.name
        const name = namehash.hash(data[0] + '.' + data[1])
        let nameHash = web3.utils.toBN(name).toString()
        localStorage.setItem('mintedTokenId', nameHash)
        window.$('#acceptOkay').modal('show')
      } catch (error) {
        setStepp(35)
        setLoader(false)
        setStep(99)
        // toast.error("Due to low gas fee the transaction didn't go through, please increase gas fee and try again", coloredToast)
        if (error?.message?.includes('User denied transaction')) {
          toast.error('User Denied Trasaction', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        } else {
          toast.info('Please Try Again', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        }
        setLoader(false)
      }
    } else {
      toast.error('Domain Is Already Minted By Some Other User', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
  }
  const allowance = async () => {
    try {
      let details = await checkAllowance(account, Environment.ethRegistrar)
      details = web3.utils.fromWei(details.toString(), 'ether')
      setAllowanceValue(details)
    } catch (error) {
    }
  }
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setTimerTime(0)
      setLoader(false)
      setStep(99)
      return null
    } else {
      // Render a countdown
      setStepp(35)
      setStep(50)
      return (
        <span>
          {minutes}:{seconds < 10 && 0}{seconds}
        </span>
      );
    }
  };
  useEffect(() => {
    if (account) {
      allowance()
      window.$('#exampleModalwallet').modal('hide')
    } else {
      window.$('#exampleModalwallet').modal(show)
    }
    let DomainName = localStorage.getItem('dName')
    let aToken = localStorage.getItem('aToken')
    if (DomainName === inpuVal + type && account === aToken) {
      let stTime = localStorage.getItem('nowTime')
      setTimerTime(parseFloat(stTime))
    }
    // getTotalStakers()
  }, [account])
  const OKay = () => {
    let MintedToken = localStorage.getItem('mintedTokenId')
    window.$('#acceptOkay').modal('hide')
    window.location.assign(`/place/${MintedToken}:mintedDomain`)
  }
  const mintPriceFun = async () => {
    const res = await domainMintingPriceMethod(inpuVal, type)
    // console.log('dlfjalsfjl', res)
    setMintPrice(res)
  }
  useEffect(() => {
    if (account) {
      if (inpuVal, type) {
        mintPriceFun()
      }
    }
  }, [account])
  useEffect(() => {
    if (mintPrice) {
      allowanceofAccount()
    }
  }, [mintPrice])

  const [buytomi, setBuytomi] = useState(false);
  const handleClosebuytomi = () => setBuytomi(false);
  const handleShowbuytomi = () => setBuytomi(true);

  const [routes, setRoutes] = useState(false);
  useEffect(() => {
    localStorage.setItem('path', 'location')
  }, [])
  var MintedToken = localStorage.getItem('mintedTokenId')
  return (
    <>
      {loader2 && <Loader />}
      <Navbar setTab={''} setTab2={'common'} setTab3={''} />
      {/* <button onClick={() => window.$('#acceptOkay').modal('show')}>sind</button> */}
      <section className="domain-search progress-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-9 col-12 m-auto">
              <div className="availableDomain mb-5">
                <div className="row">
                  <div className="col-sm-6 my-auto">
                    <div className="d-flex domainMain mb-0">
                      <p>
                        <span>{inpuVal}</span>.{type}
                      </p>
                      {avail ?
                        <div className="d-flex availtick">
                          <img src="\searchResults\whitetick.svg" alt="" />
                          <p>{parseInt(step) >= 100 ? 'Minted Successfully' : 'Available'} </p>
                        </div>
                        :
                        <div className="d-flex availtick bg-danger">
                          <img src="\searchResults\whitetick.svg" alt="" />
                          <p>Unavailable</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div className="col-xl-8 col-12 m-auto">
              <div className="progresss totoParent mt-4">
                <ProgressBar className='toto' now={step} />
                <div className="progress-barr">
                  <div className="item-bar">
                    <div className="left">
                      {parseInt(step) >= 30 ?
                        <img src="\assets\success.svg" alt="img" className='img-fluid' />
                        : <img
                          src={parseInt(stepp) === 5 ? "/assets/counterColored.svg" : "/assets/countergrey.svg"}
                          alt="img"
                          className="img-fluid"
                        />
                      }
                    </div>

                  </div>
                  <div className="item-bar">
                    <div className="center">
                      {parseInt(step) >= 70 ?
                        <img src="\assets\success.svg" alt="img" className='img-fluid' />
                        : <img
                          src={parseInt(stepp) === 35 ? "/assets/counterColored.svg" : "/assets/countergrey.svg"}
                          alt="img"
                          className="img-fluid"
                        />
                      }
                    </div>

                  </div>
                  <div className="item-bar">
                    <div className="right">
                      {parseInt(step) >= 100 ?
                        <img src="\assets\success.svg" alt="img" className='img-fluid' />
                        : <img
                          src={parseInt(stepp) === 71 ? "/assets/counterColored.svg" : "/assets/countergrey.svg"}
                          alt="img"
                          className="img-fluid"
                        />
                      }
                    </div>

                  </div>
                </div>
              </div>
              <div className="progress-barr paramsProgres">
                <div className="item-bar">
                  <div className="left">
                    <h5>
                      Request to <br />
                      register
                    </h5>
                  </div>
                </div>
                <div className="item-bar">
                  <div className="center">
                    <h5>
                      Checking <br />
                      Availability <br />
                      <span className='timor'> {timerTime > 0 ? <Countdown date={timerTime + parseInt(30000)} renderer={renderer} /> : '00:00'}</span>
                    </h5>
                  </div>

                </div>
                <div className="item-bar">
                  <div className="right">
                    <h5>
                      Complete <br />
                      Registration      </h5>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-xl-9 col-12 m-auto">
              {parseFloat(balance) < parseFloat(mintPrice) ?
                <div className="btn-bar text-right">
                  <button onClick={handleShow2} >Request To Register</button>
                </div>
                :
                <div className="btn-bar text-right">
                  {parseInt(step) >= 100 ? null :
                    loader ?
                      <h4 className='d-flex align-items-center justify-content-end pending'>TX PENDING <img className='img-fluid rotate ml-2' src="\assets\rotate-left.svg" alt="" /></h4>
                      :
                      (parseInt(step) >= 70 ? <button onClick={mintdomFinal}>Complete Registration</button> :
                        <>
                          {/*  */}
                          {parseFloat(allowanceValue) < 70 ?
                            <button onClick={() => { account ? setShow(true) : window.$('#exampleModalwallet').modal(show) }} >Request To Register</button>
                            :
                            <button onClick={() => { account ? mintdom() : window.$('#exampleModalwallet').modal(show) }} >Request To Register</button>
                          }
                        </>)
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </section>
      {/* success toast here...................... */}
      <Row>
        <ToastContainer position='top-end' className='p-3 toast-success'>
          <Toast onClose={() => setSuccess(false)} show={showSuccess} delay={3000} autohide >
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\check.svg" alt="img" className='img-fluid' />
                </div>
                <div className="right">
                  <h6>Successs</h6>
                  <p>Registration Successfull
                  </p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      {/* error toast here...................... */}
      <Row>
        <ToastContainer position='top-end' className='p-3 toast-error' >
          <Toast onClose={() => setError(false)} show={showError} delay={3000} autohide>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\cancel.svg" alt="img" className='img-fluid' />
                </div>
                <div className="right">
                  <h6>Error</h6>
                  <p>Registration Failed</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      {/* warning toast here...................... */}
      <Row>
        <ToastContainer position='top-end' className='p-3 toast-info' autohide>
          <Toast onClose={() => setWarning(false)} show={showWarning} delay={10000} autohide>
            <Toast.Header>
              <div className="parent">
                <div className="left">
                  <img src="\assets\warning.svg" alt="img" className='img-fluid' />
                </div>
                <div className="right">
                  <h6>Info</h6>
                  <p>Registered Domain will take time to show</p>
                </div>
              </div>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </Row>
      <section className="modal-mint1">
        <Modal show={show} onHide={handleClose} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Minting Fee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content">
              <div className="text">
                <h6>Price</h6>
                <h5>
                  {/* <img src="\assets\eth-modal.svg" alt="img" className="img-fluid" /> */}
                  {parseFloat(mintPrice)?.toFixed(2)} quecko</h5>
              </div>
            </div>
            <div className="">
              <a className="register-btn" style={{ width: "200px", margin: "10px auto" }} onClick={mintdom}>Approve</a>
              <a className="modal-cancel-btn" onClick={() => setShow(false)}>Cancel</a>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <section className="placebid2-modal modal-dialog-centered csghghscghcsgcsvsgcv">
        <div
          data-backdrop="static" data-keyboard="false"
          class="modal fade"
          id="acceptOkay"
          tabindex="-1"
          aria-labelledby="exampleModalLabelMinted"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body modal-body1  mt-3">
                <img
                  src="\assets\success2.svg"
                  alt="img"
                  className="img-fluid"
                />
                <p className='csgvtvscttysccstysc'>
                  Your Domain has been <br /> minted successfully
                </p>
                <p className=' font-size-sm scsvscscttsftysdtyd'> And placed on the marketplace for auction until 20th June, 2024</p>
                <div className="bottom-links-set-new">
                  <h6>Share with friends</h6>
                  <div className="inner-links">
                  {/* {`Check out my domain ${inpuVal}.${type} on tdns.network`} */}
                    <CopyToClipboard text={`https://tdns.network/place/${MintedToken}`}
                      onCopy={() => { setCopied('Copied'); settime() }}>
                      <a href="#"> <img data-toggle="tooltip" data-placement="top" title={!copied ? 'Copy' : 'Copied'} src="\assets\link-icon.svg" alt="img" className="img-fluid" /></a>
                    </CopyToClipboard>
                    <WhatsappShareButton url={`https://tdns.network/place/${MintedToken}`} title={`I just minted a new domain through DNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @quecko new internet!`}>
                      <img data-toggle="tooltip" data-placement="top" title={''} src="\assets\whatsapp-icon.svg" alt="img" className="img-fluid" />
                    </WhatsappShareButton>
                    <TwitterShareButton url={`https://tdns.network/place/${MintedToken}`} title={`I just minted a new domain through DNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @quecko new internet!`}>
                      <img src="\assets\twitter-new-icon.svg" alt="img" className="img-fluid" />
                    </TwitterShareButton>
                    <TelegramShareButton url={`https://tdns.network/place/${MintedToken}`} title={`I just minted a new domain through DNS and became the domain's NFT Partner for life. The domain is up for auction – the winning bidder gets to own a piece of @quecko new internet!`}>
                      <img src="\assets\telegram-new-icon.svg" alt="img" className="img-fluid" />
                    </TelegramShareButton>
                    {/* <a href="#"><img src="\assets\insta.svg" alt="img" className="img-fluid" /></a> */}
                  </div>
                </div>
                <button onClick={OKay} className='mt-0'>Go Checkout My Domain</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal className='oops-modal' show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton onClick={handleClose2}>
          <Modal.Title>Oops…</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inner-content">
            <div className="uper-img">
              <img src="\assets\newlanding-assets\oops-mint.svg" alt="img" className='img-fluid' />
            </div>
            <div className="bottom-content">
              <p className='main-para'>It looks like you don’t have TOMI tokens in your wallet. You will need TOMI tokens to mint tDNS domains.</p>
              <div className="inner-exchanges">
                <h6>Buy on major exchanges</h6>
                <div className="exchange-names">
                  <a href="https://www.huobi.com/en-us/trade/tomi_usdt" target='_blank'><img src="\assets\newlanding-assets\huobi.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.gate.io/trade/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\gateio.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.mexc.com/exchange/TOMI_USDT" target='_blank'><img src="\assets\newlanding-assets\mexc.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://crypto.com/exchange/trade/TOMI_USD" target='_blank'><img src="\assets\newlanding-assets\bitmart.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.bybit.com/en-US/trade/spot/TOMI/USDT" target='_blank'><img src="\assets\newlanding-assets\bybit.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.bitget.com/spot/TOMIUSDT?type=spot" target='_blank'><img src="\assets\newlanding-assets\bitget.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://phemex.com/spot/trade/TOMIUSDT" target='_blank'><img src="\assets\newlanding-assets\heli.svg" alt="img" className='img-fluid' /></a>
                  <a href="https://www.kucoin.com/trade/TOMI-USDT" target='_blank'><img src="\assets\newlanding-assets\kucoin.svg" alt="img" className='img-fluid' /></a>
                </div>
                <img src="\assets\newlanding-assets\or-img.svg" alt="img" className='img-fluid para-or' />
                <div className="twice-btns">
                  <a href="https://app.uniswap.org/" target='_blank'>    <button className='uni-swap'> <img src="\assets\newlanding-assets\uniswap.svg" alt="img" className='img-fluid mr-2' /> Buy on Uniswap</button></a>
                  <a href="https://changelly.com/" target='_blank'>    <button className='btn-chan'> <img src="\assets\newlanding-assets\changely.svg" alt="img" className='img-fluid mr-2' /> Buy on Changelly</button></a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* new modal here.............................. */}
      {/* <button onClick={handleShowbuytomi}>handleShowbuytomi</button> */}
      <Modal className='oops-modal' show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton >
          <img style={{ cursor: "pointer" }} onClick={() => { setRoutes(!routes) }} src="\assets\newlanding-assets\back-btn.svg" alt="img" className={routes ? 'img-fluid back-btn' : "d-none"} />
          <Modal.Title>Buy quecko</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !routes ?
              <div className={!routes ? "buytomi-modaldiv slide-left" : "buytomi-modaldiv"}>
                <div className="upper-img">
                  <img src="\assets\newlanding-assets\buytomi.svg" alt="img" className='img-fluid' />
                </div>
                <p className="para">It appears that you don’t have any quecko tokens in your wallet. In order to mint DNS domains, you will require quecko tokens</p>
                <button onClick={() => setRoutes(!routes)} className='buytomi-btn'>Buy QUECKO</button>
              </div> : <>
                <div className={routes ? "another-div slide-right" : "another-div"}>
                  <div className="parent-items">
                    <a href="https://www.kucoin.com/trade" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\kucoin.svg" alt="img" className='img-fluid' />
                        <h6>KuCoin</h6>
                      </div>
                    </a>
                    <a href="https://www.bybit.com/en-US/trade/spot" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\bybit.svg" alt="img" className='img-fluid' />
                        <h6>ByBit</h6>
                      </div>
                    </a>
                    <a href="https://www.huobi.com/en-us/trade" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\huobi.svg" alt="img" className='img-fluid' />
                        <h6>Huobi</h6>
                      </div>
                    </a>
                    <a href="https://www.gate.io/trade" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\gateio.svg" alt="img" className='img-fluid' />
                        <h6>Gate.io</h6>
                      </div>
                    </a>
                    <a href="https://www.mexc.com/exchange" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\mexc.svg" alt="img" className='img-fluid' />
                        <h6>Mexc</h6>
                      </div>
                    </a>
                    <a href="https://crypto.com/exchange/trade" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\crypto.svg" alt="img" className='img-fluid' />
                        <h6>Crypto.com</h6>
                      </div>
                    </a>
                    <a href="https://www.bitget.com/spot" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\bitget.svg" alt="img" className='img-fluid' />
                        <h6>Bitget</h6>
                      </div>
                    </a>
                    <a href="https://phemex.com/spot/trade" target='_blank'>
                      <div className="inner-item">
                        <img src="\assets\newlanding-assets\exchangesicons\phemex.svg" alt="img" className='img-fluid' />
                        <h6>Phemex</h6>
                      </div>
                    </a>
                  </div>
                  <img src="\assets\newlanding-assets\or-img.svg" alt="img" className='img-fluid para-or' />
                  <div className="twice-btns">
                    <a href="https://app.uniswap.org/#/swap?outputCurrency=0x4385328cc4d643ca98dfea734360c0f596c83449" target='_blank'>    <button className='uni-swap'> <img src="\assets\newlanding-assets\uniswap.svg" alt="img" className='img-fluid mr-2' /> Buy on Uniswap</button></a>
                    <a href="https://changelly.com/" target='_blank'>    <button className='btn-chan'> <img src="\assets\newlanding-assets\changely.svg" alt="img" className='img-fluid mr-2' /> Buy on Changelly</button></a>
                  </div>
                </div>
              </>
          }

        </Modal.Body>
      </Modal>
    </>
  )
}
export default ProgressBarComp;