import React from 'react'
import { Link } from 'react-router-dom'
import "./partner.scss"

const Partner = () => {
  return (
    <>
      <section className="partner">
        <div className="container">
            <div className="main-card">
                <div className="upper-heading">
                    <h5>Partner with us</h5>
                </div>
                <div className="inner-card-parent">
                <div className="inner-card">
                    <img src="\assets\strength.svg" alt="img" className='img-fluid' />
                    <h6 className='text-capitalized'>Build revenue and increase brand strength</h6>
                </div>
                <div className="inner-card">
                    <img src="\assets\technology.svg" alt="img" className='img-fluid' />
                    <h6>Get early access to innovative technology</h6>
                </div>
                <div className="inner-card">
                    <img src="\assets\provider.svg" alt="img" className='img-fluid' />
                    <h6>Provide an entry point to Web3</h6>
                </div>
                </div>
                <div className="bottom-text">
                    <Link to="/mail">Become a partner <img src="\assets\arrow-right.svg" alt="img" className='img-fluid ml-2' /></Link>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Partner
