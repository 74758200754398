import React from 'react'
import { Link } from 'react-router-dom'
import './cryptoApp.scss'
function CryptoApp() {
    return (
        <section className="creditCard-main cryptoMain-main">
            <div className="row">
                <div className="col-md-8 pl-md-0">
                    <div className="cryptoHeading text-center">
                        <h1>Pay with the Crypto.com App</h1>
                        <p>Crypto.com App Users are eligible to enjoy Up to 10% in rebates upon checking out with Crypto.com Pay through the Pay Rewards Program.
                            Click here for more information on Pay Rewards.</p>
                        <p>Download the app here:</p>
                        <img src="\chekout\appStore.svg" className='img-fluid mb-3' alt="" />

                       <Link to="/orderComplete"> <button className="img-btn mt-5 w-100"><img src="\chekout\cryptoPlusImg.svgs" alt="" /></button></Link>
                    </div>
                </div>
                <div className="col-md-4 mt-md-0 mt-5">
                    <div className="orderSummary">
                        <div className="heading">
                            <h3>Order Summary</h3>
                            <p>See order details</p>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <div>
                                <h6>Total Due</h6>
                                <h4>$260.00</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section >
    )
}

export default CryptoApp