import React, { useState } from 'react'
import "./commission.scss"
import Modal from 'react-bootstrap/Modal';
import TableRow from './TableRow';

const DataTable = ({ mintedData, setLoader, setApiCallStatus, apiStatus, additionalDataArr }) => {
    const [mintedFilterData, setMintedFilterDAta] = useState([])
    const [inputVal, setInputValue] = useState(null)
    const [limit, setLimit] = useState(10)
    const findNft = async (e) => {
        setInputValue(e.target.value)
        let res = additionalDataArr?.filter((item) => {
            return (
                item?.label?.toLowerCase().includes(e.target.value.toLowerCase())
            )
        })

        setMintedFilterDAta(res)
    }
    return (
        <>
            <section className="datatable">
                <div class="table-responsivecolorbg">
                    <div className="upper-content">
                        {/* <div className="left">
                            <span>Show</span>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {limit}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a onClick={() => setLimit(5)} class="dropdown-item">5</a>
                                    <a onClick={() => setLimit(10)} class="dropdown-item">10</a>
                                    <a onClick={() => setLimit(100)} class="dropdown-item">100</a>
                                </div>
                            </div>
                            <span>entries</span>
                        </div> */}
                        {/* <div className="right">
                            <div className="option-field">
                                <h6>Search:</h6>
                                <input onChange={findNft} type="search" />
                            </div>
                        </div> */}
                    </div>
                    <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="parent-tag">
                                            Date Added <div className="filter-btn">
                                                <img src="\assets\upper-arrow.svg" alt="img" className='img-fluid' />
                                                <img src="\assets\down-arrow.svg" alt="img" className='img-fluid' />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="parent-tag">
                                            Domain <div className="filter-btn">
                                                <img src="\assets\upper-arrow.svg" alt="img" className='img-fluid' />
                                                <img src="\assets\down-arrow.svg" alt="img" className='img-fluid' />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="parent-tag">
                                            Commission <div className="filter-btn">
                                                <img src="\assets\upper-arrow.svg" alt="img" className='img-fluid' />
                                                <img src="\assets\down-arrow.svg" alt="img" className='img-fluid' />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="parent-tag setpaddrightmbl" style={{justifyContent: "flex-end", paddingRight: "50px"}}>
                                            Action
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {/* {(inputVal ? mintedFilterData : additionalDataArr)?.map((item, id) => { ?.slice(0, limit) */}
                                {additionalDataArr?.map((item, id) => {
                                    return (
                                            <TableRow item={item} partnerId={item?.partnerId} setLoader={setLoader} setApiCallStatus={setApiCallStatus} apiStatus={apiStatus} />  
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="footer-content">
                        <div className="left-f">
                            <h6>Showing 1 to 10 of 57 entries</h6>
                        </div>
                        <div className="right-f">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default DataTable
