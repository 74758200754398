import React from 'react'
import { Link } from 'react-router-dom'
import './creditCard.scss'
function CreditCard() {
    return (
        <section className="creditCard-main">
            <div className="row">
                <div className="col-md-8 pl-md-0">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <p className='label' htmlFor="">Your Card Number</p>
                            <input type="text" name="" placeholder='1234  1234  1234  1234' id="" />
                        </div>
                        <div className="col-md-6 pl-md-0">
                            <p className='label' htmlFor="">Expire</p>
                            <input type="text" name="" placeholder='MM/YY' id="" />
                        </div>
                        <div className="col-md-6 pr-md-0">
                            <p className='label' htmlFor="">Your Card Number</p>
                            <input type="text" name="" placeholder='MM/YY' id="" />
                        </div>
                        <div className="col-12 mb-2 px-0">
                            <p className='label' htmlFor="">Postal Code</p>
                            <input type="text" name="" placeholder='xxxxxx' id="" />
                        </div>
                        <Link className='w-100' to="/orderComplete">  <button className='img-btn mt-4 w-100'>Submit Payment</button></Link>
                    </div>
                </div>
                <div className="col-md-4 mt-md-0 mt-5">
                    <div className="orderSummary">
                        <div className="heading">
                            <h3>Order Summary</h3>
                            <p>See order details</p>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <span>
                                <h6>Domain</h6>
                                <p>diligent.crypto</p>
                            </span>
                            <h4>$260.00</h4>
                        </div>
                        <div className="domain-price">
                            <div>
                                <h6>Total Due</h6>
                                <h4>$260.00</h4>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default CreditCard