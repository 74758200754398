import axios from 'axios'
import { async } from 'q'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Navbar from '../landing/header/Navbar'
import "./mail.scss"

const Mail = () => {
    const [loader, setLoader]=useState(false)
    const [partner, setPartner] = useState({
        name: '',
        email: '',
        designation: '',
        appName: '',
        appWebUri: '',
        totalUsers: '',
        companyType: '',
        mobileNumber: ''
    })
    const partnerInpo = async (e) => {
        let { name, value } = e.target
        setPartner((pre) => {
            return (
                { ...pre, [name]: value }
            )
        })
    }

    const submitForm = async (obj) => {
        obj.preventDefault();
        if (partner.name && partner?.email && partner?.designation && partner?.appName && partner?.appWebUri && partner?.totalUsers && partner?.companyType) {
            setLoader(true)
            axios.post('https://staging.be.tomi.com/support/tdns-partners', {
                name: partner?.name,
                email: partner?.email,
                designation: partner?.designation,
                appName: partner?.appName,
                appWebUri: partner?.appWebUri,
                totalUsers: partner?.totalUsers,
                companyType: partner?.companyType,
                mobileNumber: partner?.mobileNumber
            })
                .then((response) => {
                    setLoader(false)
                    toast.success('dns Partner Request created successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }).catch((err) => {
                    setLoader(false)
                    toast.error(err?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
        } else {
            toast.error('Fill All the fields!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }

    }
    return (
        <>
            <Navbar />
            <section className="mail">
                <div className="container">
                    <div className="mail-box">
                        <div className="upper-heading">
                            <h5>Interested in Becoming a Partner?</h5>
                            <p>Before we talk, tell us a little about your business. That way we can jump right in to the good stuff.</p>
                        </div>
                        <form onSubmit={submitForm}>
                            <div className="bottom-content">
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='name' type="text" placeholder='Name' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='email' type="email" placeholder='Email' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='designation' type="text" placeholder='Job title / Designation' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='appName' type="text" placeholder='App Name' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='appWebUri' type="url" placeholder='App website URL' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='totalUsers' type="number" placeholder='Total users' />
                                </div>
                                <div className="option-field">
                                    <input required onChange={partnerInpo} name='mobileNumber' type="number" placeholder='Mobile No' />
                                </div>
                                <div className="option-field ">
                                    <input required onChange={partnerInpo} name='companyType' type="text" placeholder='Type of company' />
                                </div>
                            </div>
                            <div className="commonnn">
                                <button type='submit' className='btn-common'>
                                    {loader ?
                                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                                            <img
                                                width={35}
                                                style={{
                                                    filter: 'invert(97%) sepia(98%) saturate(8%) hue-rotate(171deg) brightness(313%) contrast(103%)'
                                                }}
                                                src="\loader\loader.gif"
                                                alt="loader"
                                            />
                                        </div>
                                        :
                                        'Submit'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mail
