import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CashBack from '../../hooks/dataFetchers/cashback'
import AffiliateClaimReward from '../../hooks/dataSenders/affiliateClaimReward'
import { useWeb3React } from '@web3-react/core';
import { API_URL } from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

function TableRow({ item, setLoader, setApiCallStatus, apiStatus, setClaimIds, claimIds, setShow1, show1, setShow, show }) {
    const [format, setFormat] = useState()
    const [cashback, setCashBack] = useState(0)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { currCashBack } = CashBack()
    const { ClaimAffiliate } = AffiliateClaimReward()
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const { account } = useWeb3React()
    // console.log('first')
    const claimCommisionValue = async () => {
        if (account) {
            try {
                setLoader(true)
                let tok = localStorage.getItem("accessToken");
                let data = {
                    compensationIds: [item?._id],
                    walletAddress: account?.toLowerCase()
                }
                var config = {
                    method: "patch",
                    url: `${API_URL}/compensation/claim`,
                    data: data,
                    headers: {
                        authorization: `Bearer ` + tok
                    }
                };
                axios(config)
                    .then(async function (response) {
                        let token = null;
                        let amount = null;
                        let v = null;
                        let r = null;
                        let s = null;
                        for (let i of response?.data?.data) {
                            token = i.tokenId;
                            amount = i.amount;
                            v = i?.v;
                            r = i?.r;
                            s = i?.s;
                        }
                        try {
                            const claimed = await ClaimAffiliate(token, amount, account, v, r, s, item?._id)
                            setClaimIds([]);
                            handleShow()
                        } catch (err) {
                            if (err?.code) {
                                let data = {
                                    compensationIds: [item?._id],
                                    walletAddress: account?.toLowerCase()
                                }
                                var config = {
                                    method: "patch",
                                    url: `${API_URL}/compensation/claim/revert`,
                                    data: data,
                                    headers: {
                                        authorization: `Bearer ` + tok
                                    }
                                };
                                axios(config)
                                    .then(async function (response) {
                                        setLoader(false)
                                    }).catch(function (error) {
                                        handleShow1()
                                    });
                                // console.log('first', error)
                                setLoader(false)
                                handleShow1()
                            }
                        }
                        setLoader(false)
                        setApiCallStatus(!apiStatus)
                    }).catch(function (error) {
                        let data = {
                            compensationIds: [item?._id],
                            walletAddress: account?.toLowerCase()
                        }
                        var config = {
                            method: "patch",
                            url: `${API_URL}/compensation/claim/revert`,
                            data: data,
                            headers: {
                                authorization: `Bearer ` + tok
                            }
                        };
                        axios(config)
                            .then(async function (response) {
                                setLoader(false)
                            }).catch(function (error) {
                                handleShow1()
                            });
                        // console.log('first', error)
                        setLoader(false)
                    });
            } catch (error) {
                handleShow1()
                // console.log('first', error)
                setLoader(false)
            }

        } else {
            toast.info('Connect Wallet')
        }
    }

    const CheckVal = (item) => {
        let arr = claimIds;
        let dumObj = arr?.find((i) => {
            return i?._id === item?._id;
        })
        if (dumObj) {
            arr = arr?.filter((i) => {
                return i?._id !== item?._id;
            })
        } else {
            arr?.push(item?._id);
        }
        setClaimIds(arr);
    }

    return (
        <>
            <tr>
                <td>
                    <div className="custom-checkbox">
                        {item?.claimed === false &&
                            <div class="form-group">
                                <input checked={claimIds?.find((i) => i === item?._id)} onChange={(e) => CheckVal(item)} type="checkbox" id={item?._id} />
                                <label for={item?._id}></label>
                            </div>
                        }
                    </div>
                </td>
                <td>{item?.label || '--'}.{item?.tld || '--'}</td>
                <td>{item?.level}</td>
                <td>{item?.amount / 10 ** 18}</td>
                <td>{item?.referrerWallet}</td>
                <td>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</td>
                {/* <td><button disabled={true} onClick={claimCommisionValue} className={'bbbtn-claim btnDisable'} >{'Claim'}</button></td> */}
                <td><button disabled={item?.claimed} onClick={claimCommisionValue} className={item?.claimed ? 'bbbtn-claim btnDisable' : 'bbbtn-claim'} >{item?.claimed ? 'Claimed' : 'Claim'}</button></td>
            </tr>
            <Modal className='success-modal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\success2.svg" alt="img" className='img-fluid' />
                        <h6> Claimed Successfully</h6>
                    </div>
                    <button onClick={handleClose} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>

            <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
                        <h6>Failed to claim commission. try again</h6>
                    </div>
                    <button onClick={handleClose1} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TableRow