import React, { useEffect, useState } from "react";
import "./myDomain.scss";
import "../domainSearch.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../../utils/Environment";
import ChildOfId from '../../../hooks/dataFetchers/myDomains'
import { toast } from "react-toastify";
import CheckExpire from "../../../hooks/dataFetchers/checkExpiry";
import ClaimNft from "../../../hooks/dataFetchers/checkClaim";
import { useHistory } from 'react-router'
function MyDomain({ setLoader, minted, SecondParam, loader }) {
  const history = useHistory()
  const { account } = useWeb3React()
  const [morData, setMorData] = useState()
  const [actualDomainArr, setActualDomainArr] = useState([])
  const [filteredDatas, setFilteredData] = useState()
  let currTab = SecondParam === 'minted' && SecondParam || 'all'
  const [tab, setTab] = useState(currTab)
  const [mintedData, setMintedData] = useState()
  const [mintedDataClaimed, setMintedDataClaimed] = useState()
  const [partnerNfts, setPartnerNfts] = useState()
// console.log('partnerNfts',partnerNfts?.length,  partnerNfts,allDataArray )
  const [error, setError] = useState()
  const { CheckClaimNft } = ClaimNft();
  const { CheckExpiry } = CheckExpire();
  const { Mainfunct } = ChildOfId();
  const getMinted = () => {
    var config = {
      method: "get",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft/${Environment.partnerNFT}?chain=eth&format=decimal`,
      headers: {
        "x-api-key":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZlMGI5MjNkLTNkYmYtNDBlZC04MGY4LTU3NWVhODEwYjc0ZiIsIm9yZ0lkIjoiMjA3ODg5IiwidXNlcklkIjoiMjA3NTYxIiwidHlwZUlkIjoiZWMwMWYzMDctNTJhMS00ZTNiLWExYzgtNWM3MmQwOGExZDc1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODczNTcwNDYsImV4cCI6NDg0MzExNzA0Nn0.Uv0uw5Q_lnSgXMquIJ0GHKlx_uNMkdDptrT00MzmwPo",
      },
    };
    axios(config)
      .then(async function (response) {
        setLoader(true)
        let minted = response.data.result;
        let dumArr = [];
        for (let key in minted) {
          setLoader(true)
          try {
            let response = await axios.get(`https://metadata.tdns.network/partner/getData/${minted[key]?.token_id}`)
            try {
              var data = JSON.stringify({
                query: `
                query MyQuery {
                  auctionCreateds(
                    where: {label: "${response?.data?.name?.split('.')[0]}", tld: "${response?.data?.name?.split('.')[1]}"}
                  ) {
                    expiryTime
                    id
                    isClaimed
                    label
                    labelhash
                    mintAmount
                    minter
                    startTime
                    tld
                    tokenId
                  }
                }`,
                variables: {},
              });
              var config = {
                method: "post",
                url: Environment.marketplaceGraph,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              let res = await axios(config)
              if(res?.data?.data?.auctionCreateds[0]){
                dumArr.push(res?.data?.data?.auctionCreateds[0])
              }else{
                dumArr.push()
              }
            } catch (error) {
              dumArr.push()
              setLoader(false)
            }
            // console.log('fdssddsfsdfdf', response, key)
          } catch (error) {
            setLoader(false)
          }
          setLoader(false)
        }
        setPartnerNfts(dumArr)
        setLoader(false)
      })
      .catch(function (error) {
        // console.log('partnerNft', error)
        // // console.log(error);
        setPartnerNfts([])
        setLoader(false)

      });
  };
  const getNftsdata = async () => {
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `{
          domains(where: {registration_: {registrant: "${account && account.toLocaleLowerCase()}"}}
        ) {
          id
          name
          tld
          labelName
          labelhash
          registration {
            registrant {
              id
            }
          }
        }
      }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.dnsGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setLoader(true);
          setMorData(response?.data?.data?.domains)
          myDomainsFun(response?.data?.data?.domains)
          setActualDomainArr(response?.data?.data?.domains)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getMinteddata = async () => {
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
     query MyQuery {
      auctionCreateds(where: {minter: "${account && account.toLocaleLowerCase()}"}) {
        id
        tokenId
        minter
        mintAmount
        startTime
        expiryTime
        label
        labelhash
        tld
        isClaimed
      }
    }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // setLoader(true);
          // setMorData(response?.data?.data?.domains)
          // myDomainsFun(response?.data?.data?.domains)
          // setActualDomainArr(response?.data?.data?.domains)
          setMintedData(response?.data?.data?.auctionCreateds)
          // // console.log('result of the minteddomain---->', response)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          // setNft([]);
          // // console.log('result of the myDomains---->', error);
        });
    }
  };
  // const getMinteddataClaimed = async () => {
  //   if (account) {
  //     setLoader(true)
  //     var data = JSON.stringify({
  //       query: `query MyQuery {
  //   auctionCreateds {
  //     expiryTime
  //     id
  //     isClaimed
  //     label
  //     labelhash
  //     mintAmount
  //     minter
  //     startTime
  //     tld
  //     tokenId
  //   }
  // }`,
  //       variables: {},
  //     });
  //     var config = {
  //       method: "post",
  //       url: Environment.marketplaceGraph,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(async (response) => {
  //         setLoader(true);
  //         let minted = response?.data?.data?.auctionCreateds;
  //         var dumSold = [];
  //         for (let nft of minted) {
  //           if (nft?.minter?.toLowerCase() === account?.toLowerCase()) {
  //             const res = await CheckClaimNft(nft?.tokenId);
  //             let res1 = await CheckExpiry(nft?.tokenId);
  //             let dat = new Date(0);
  //             dat.setUTCSeconds(parseFloat(res1?.expiryTime));
  //             if (res1?.expiryTime) {
  //               const date0 = new Date();
  //               if (!(dat > date0)) {
  //                 if (res) {

  //                   var sold = res?.isClaimed;
  //                   if (sold === false) {
  //                     dumSold.push(nft);
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         setMintedDataClaimed(dumSold);
  //         setLoader(false);
  //       })
  //       .catch(function (error) {
  //         setLoader(false);
  //       });
  //   }
  // };
  function arrayUnique(array) {
    var a = array?.concat();
    for (var i = 0; i < a?.length; ++i) {
      for (var j = i + 1; j < a?.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }

    return a;
  }
  var allDataArray = arrayUnique(actualDomainArr?.concat(partnerNfts));
  const myDomainsFun = (rawArray) => {
  }
  const filteredDomains = (e) => {
    const res = actualDomainArr.filter((item) => {
      return item?.name?.toLowerCase()?.includes(e.toLocaleLowerCase())
    })
    setFilteredData(res)
  }
  const Ascending = () => {
    const res = actualDomainArr.sort((a, b) => {
      if (a?.name?.charAt(0)?.toLocaleLowerCase() < b?.name?.charAt(0)?.toLocaleLowerCase()) {
        return -1
      }
    })

    setFilteredData(res)
  }
  const Descending = () => {
    setFilteredData('')
    const res = actualDomainArr.sort((a, b) => {
      if (a?.domain?.name?.charAt(0)?.toLocaleLowerCase() > b?.domain?.name?.charAt(0)?.toLocaleLowerCase()) {
        return 1
      }
    })
    setFilteredData(res)
  }
  const UrlEncode = (item) => {
    if (!item?.labelName) {
      history.push(`/place/${item?.tokenId}:mintedDomain`)
    } else {
      const data = item; //item is object
      history.push({
        pathname: '/domainprofile',
        state: { data },
      });
    }
  }
  const UrlEncode2 = (item) => {
    const data = item;
    history.push({
      pathname: '/domainprofile',
      state: { data },
    });
  }

  useEffect(() => {
    if (account) {
      getNftsdata()
      getMinteddata()
      // getMinteddataClaimed()
      getMinted()
    }
  }, [account, minted])
  useEffect(() => {
    if (partnerNfts?.length < 1 && !loader) {
      setError(true)
    }
  }, [partnerNfts])
  return (
    <section className="myDomain domain-search">
      <div className="domainH">
        <div className="row">
          <div className="col-6 p-0 my-auto">
            <div className="mydomainH">
              <h2>My Domains</h2>
            </div>
          </div>
        </div>
      </div>

      <ul class="nav nav-pills my-navs-one" id="pills-tab1" role="tablist">
        <li class="nav-item" onClick={() => setTab('all')}>
          <a
            class={tab === 'all' ? "nav-link active" : "nav-link"}>
            All
          </a>
        </li>
        <li class="nav-item" onClick={() => setTab('owned')}>
          <a
            class={tab === 'owned' ? "nav-link active" : "nav-link"}

          >
            Owned
          </a>
        </li>
        <li class="nav-item" onClick={() => setTab('minted')}>
          <a
            class={tab === 'minted' ? "nav-link active" : "nav-link"}>
            Partner
          </a>
        </li>
        <li class="nav-item" onClick={() => setTab('unclaimed')}>
          <a
            class={tab === 'unclaimed' ? "nav-link active" : "nav-link"}>
            Unclaimed
          </a>
        </li>
        {/* <li class="nav-item" onClick={() => setTab('partner')}>
          <a
            class={tab === 'partner' ? "nav-link active" : "nav-link"}>
            Partner
          </a>
        </li> */}
      </ul>
      {tab === 'all' &&
        <div class="tab-content" id="pills-tabContent1">

          {/* {// console.log('allDataArray?.length', allDataArray?.length, allDataArray)} */}
          <div className="table-responsive">
            <div className="availableDomain-trans">
              {(allDataArray?.length === 0 || allDataArray[0] === undefined) &&
                <div className="row">
                  <div className="col-sm-12 my-auto mx-auto">
                    <div className="d-flex justify-content-center domainMain">
                      {!account ?
                        <p className="d-flex flex-column justify-content-center align-items-center align-items-center">Connect your wallet to see your domains <button className="button-hedaer mt-2 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button> </p>
                        :

                        <>
                          {error &&
                            <h3>No Domain <span className="common">Found!</span> </h3>}
                        </>
                      }</div>
                  </div>
                </div>
              }
              {(allDataArray?.length === 0 || allDataArray[0] === undefined) ||
                <div class="tab-content dycydvcy" id="pills-tabContent1">
                  <div className="custom-cards-domain">
                    {allDataArray[0]===0 || allDataArray?.map((item, id) => {
                      return (
                        <>


                          <div className="card-manage">
                            <div className="img-main">
                              <span className={!item?.labelName ? " abs-text-golden" : 'abs-text'}>{item?.label?.split('[')[0]?.substring(0, 25) || item?.labelName?.split('[')[0]?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.label || item?.labelName)?.length > 25 && '(...)'}.{item?.tld}</span>
                              <img src={!item?.labelName ? "/mintCard/nftBgtwo3.svg" : "/assets/nftBg.svg"} alt="img" className="img-fluid w-100" />
                            </div>
                            <div className="btn-manage">
                              <Link onClick={() => UrlEncode(item)} >
                                <button className={!item?.labelName ? "btn-transparent btn-border" : "btn-transparent btn-filled"}>{!item?.labelName ? 'Details' : 'Manage'}</button>
                              </Link>
                            </div>

                          </div>



                        </>
                      )
                    })}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
      {tab === 'owned' &&
        <div class="tab-content" id="pills-tabContent1">

          <div className="table-responsive">
            <div className="availableDomain-trans">
              {(filteredDatas ? filteredDatas : actualDomainArr)?.length === 0 &&
                <div className="row">
                  <div className="col-sm-12 my-auto mx-auto">
                    <div className="d-flex justify-content-center domainMain">
                      {!account ?
                        <p className="d-flex flex-column justify-content-center align-items-center">Connect your wallet to see your domains <button className="button-hedaer mt-2 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button> </p>
                        :
                        loader || <h3>No Domain <span className="common">Found!</span> </h3>
                      }</div>
                  </div>
                </div>
              }
              <div class="tab-content dycydvcy" id="pills-tabContent1">
                <div className="custom-cards-domain">
                  {(filteredDatas ? filteredDatas : actualDomainArr)?.map((item, id) => {
                    return (
                      <>

                        <div className="card-manage">
                          <div className="img-main">
                            <span className="abs-text">{item?.name?.split('[')[0]?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.name)?.length > 25 && '(...)'}</span>
                            <img src="\assets\nftBg.svg" alt="img" className="img-fluid w-100" />
                          </div>
                          <div className="btn-manage">
                            <Link onClick={() => UrlEncode2(item)} >
                              <button className="btn-transparent btn-filled">Manage</button>
                            </Link>
                          </div>

                        </div>

                      </>
                    )
                  })}
                </div>
              </div>
            </div>


          </div>

        </div>
      }
      {
        tab === 'minted' &&
        <div class="tab-content" id="pills-tabContent1">
          <div className="table-responsive">
            <div className="availableDomain-trans">
              {/* {// console.log('allDataArray?.length====', mintedData)} */}
              {(partnerNfts?.length === 0 || partnerNfts === undefined) &&
                <div className="row">
                  <div className="col-sm-12 my-auto mx-auto">
                    <div className="d-flex justify-content-center domainMain">
                      {!account ?
                        <p className="d-flex flex-column justify-content-center align-items-center">Connect your wallet to see your domains <button className="button-hedaer mt-2 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button> </p>
                        :
                        loader || <h3>No Domain <span className="common">Found!</span> </h3>
                      }</div>
                  </div>
                </div>
              }
              <div class="tab-content dycydvcy" id="pills-tabContent1">
                <div className="custom-cards-domain">
                  {(partnerNfts)?.map((item, id) => {
                    return (
                      <>


                        <Link to={`/place/${item?.tokenId}:mintedDomain`}> <div className="card-manage">
                          <div className="img-main">
                            <span className="abs-text abs-text-golden">{item?.label?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.label)?.length > 25 && '(...)'}.{item?.tld}</span>
                            <img src="\mintCard\nftBgtwo3.svg" alt="img" className="img-fluid w-100" />
                          </div>
                          <div className="btn-manage">
                                <Link to={`/place/${item?.tokenId}:mintedDomain`}>
                                <button className="btn-transparent">Details</button>
                                </Link>
                              </div>

                        </div>
                        </Link>

                        {/* <div id={id} className="row">
              <div className="col-sm-6 my-auto">
                <div className="d-flex domainMain">
                  <img
                    style={{ width: '30px' }}
                    src="\searchResults\polyInfinite.svg"
                    className="mr-2"
                    alt=""
                  />
                  <p>
                    {// // console.log("item", item)}
                    <span>{item?.label}</span>
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex justify-content-md-end domainRightHeartDiv ">
                  <Link to={`/place/${item?.tokenId}:minted`}>
                    <button className=" img-btn-outline">
                      <img src="\searchResults\setting.svg" alt="" />
                      Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>  */}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>
        </div>
      }
      {tab === 'unclaimed' &&
        <div class="tab-content" id="pills-tabContent1">
          <div className="table-responsive">
            <div className="availableDomain-trans">
              {/* {// console.log('ddddddddddd', mintedDataClaimed)} */}
              {mintedDataClaimed?.length < 1 || mintedDataClaimed === undefined &&
                <div className="row">
                  <div className="col-sm-12 my-auto mx-auto">
                    <div className="d-flex justify-content-center domainMain">
                      {!account ?
                        <p className="d-flex flex-column justify-content-center align-items-center">Connect your wallet to see your domains <button className="button-hedaer mt-2 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button> </p>
                        :
                        <h3>No Domain <span className="common">Found!</span> </h3>
                      }</div>
                  </div>
                </div>
              }
              <div class="tab-content dycydvcy" id="pills-tabContent1">
                <div className="custom-cards-domain">
                  {mintedDataClaimed?.map((item, id) => {
                    return (
                      <>


                        <div className="card-manage">
                          <div className="img-main">
                            <span className="abs-text">{item?.label?.split('[')[0]?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.name)?.length > 25 && '(...)'}.{item?.tld}</span>
                            <img src="\assets\nftBg.svg" alt="img" className="img-fluid w-100" />
                          </div>
                          <div className="btn-manage">
                            <Link to={`/place/${item?.id}:mintedDomain`}>
                              <button className="btn-transparent">Claim</button>
                            </Link>
                          </div>

                        </div>


                      </>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>

        </div>
      }
      {
        tab === 'partner' &&
        <div class="tab-content" id="pills-tabContent1">
          <div className="table-responsive">
            <div className="availableDomain-trans">
              {/* {// console.log('allDataArray?.length====', mintedData)} */}
              {(partnerNfts?.length === 0 || mintedData === undefined) &&
                <div className="row">
                  <div className="col-sm-12 my-auto mx-auto">
                    <div className="d-flex justify-content-center domainMain">
                      {!account ?
                        <p className="d-flex flex-column justify-content-center align-items-center">Connect your wallet to see your domains <button className="button-hedaer mt-2 m-0" data-toggle="modal"
                          data-target="#exampleModalwallet">Connect Wallet</button> </p>
                        :
                        <h3>No Partner Domain <span className="common">Found!</span> </h3>
                      }</div>
                  </div>
                </div>
              }
              <div class="tab-content dycydvcy" id="pills-tabContent1">
                <div className="custom-cards-domain">
                  {(partnerNfts)?.map((item, id) => {
                    return (
                      <>


                        {/* <Link to={``}> */}
                        <div className="card-manage">
                          <div className="img-main">
                            <span className="abs-text abs-text-golden">{item?.data?.name}</span>
                            <img src="\mintCard\nftBgtwo3.svg" alt="img" className="img-fluid w-100" />
                          </div>
                          {/* <div className="btn-manage">
                                <Link to={`/place/${item?.tokenId}:minted`}>
                                <button className="btn-transparent">Manage</button>
                                </Link>
                              </div> */}

                        </div>
                        {/* </Link>  */}


                        {/* <div id={id} className="row">
              <div className="col-sm-6 my-auto">
                <div className="d-flex domainMain">
                  <img
                    style={{ width: '30px' }}
                    src="\searchResults\polyInfinite.svg"
                    className="mr-2"
                    alt=""
                  />
                  <p>
                    {// // console.log("item", item)}
                    <span>{item?.label}</span>
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex justify-content-md-end domainRightHeartDiv ">
                  <Link to={`/place/${item?.tokenId}:minted`}>
                    <button className=" img-btn-outline">
                      <img src="\searchResults\setting.svg" alt="" />
                      Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>  */}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>
        </div>
      }
    </section >
  );
}
export default MyDomain;
