import React, { useEffect, useState } from 'react'
import "./live.scss"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import CheckExpire from "../../../hooks/dataFetchers/checkExpiry";
import Environment from "../../../utils/Environment";
import { useWeb3React } from '@web3-react/core';
import axios from "axios";
import { Link } from 'react-router-dom';
import Market from '../../Marketplace/Market';
const LiveAuction = () => {
    const [expTime, setExpTime]=useState([])
    const [allNFTS, setAllNFTS] = useState([]);
    const [loader, setLoader] = useState(false)
    // console.log('asfkd;a;', allNFTS)
    const { account } = useWeb3React()
    const { CheckExpiry } = CheckExpire();
    // const expTimeFun = (arr) => {
    //     console.log('adlfjalsdfjlasfd',arr)
    //     setLoader(true);
    //     var data = JSON.stringify({
    //         query: `query MyQuery {
    //             auctionCreateds(
    //               orderBy: mintAmount
    //               orderDirection: asc
    //               where: {tokenId_in: ${JSON.stringify(arr)}}
    //             ) {
    //               expiryTime
    //               label
    //               tokenId
    //             }
    //           }`,
    //         variables: {},
    //     });

    //     var config = {
    //         method: "post",
    //         url: Environment.marketplaceGraph,
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     };

    //     axios(config)
    //         .then(async (response) => {
    //             setLoader(true);
    //             var dumAuction = [];
    //             let minted = response?.data?.data?.auctionCreateds;
                
                
    //             for (let nft of minted) {
    //                 let dat = new Date(0);
    //                 dat.setUTCSeconds(parseFloat(nft?.expiryTime));
    //                 dumAuction.push(dat)
    //             }
    //             setExpTime(dumAuction);
    //             setLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log('sdf', err)
    //             setLoader(false);
    //         });
    //     setLoader(true);
    // };
    const OnAuction = () => {
        setLoader(true);
        var data = JSON.stringify({
            query: `query MyQuery {
                bidCreateds(first: 20, orderBy: amount, orderDirection: desc) {
                  amount
                  label
                  tokenId
                  tld
                }
              }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: Environment.marketplaceGraph,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(async (response) => {
                setLoader(true);
                var dumAuction = [];
                let minted = response?.data?.data?.bidCreateds;
                const uniquePairs = new Set();
               const uniqueData = [];

               minted.forEach(obj => {
                 const pair = obj.label + obj.tld;
  
                 if (!uniquePairs.has(pair) && uniqueData.length < 8) {
                  uniquePairs.add(pair);
                  uniqueData.push(obj);
                }
});

console.log(uniqueData);
                setAllNFTS(uniqueData);
                // let dumArr=[]
                // for (let nft of minted) {
                //     dumArr.push(nft?.tokenId?.toString())
                // }
                // console.log('dummarr',dumArr)
                setLoader(false);
            })
            .catch((err) => {
                // console.log('sdf', err)
                setLoader(false);
            });
        setLoader(true);
    };
    function formatAmount(amount) {
      return (parseInt(amount) / 1000000000000000000)?.toFixed(2);
    }
    
    function displayAmount(amount) {
      const parsedAmount = formatAmount(amount);
      if (parsedAmount && parsedAmount >= 10000000) {
        return `${parsedAmount?.toString()?.slice(0, 9)}...`;
      }
      return parsedAmount;
    }
    useEffect(() => {
        OnAuction()
    }, [account])
    let metaData= [0,1]
    const display = allNFTS
    ?.map((elem, id) => {
      return (
        <div className="custom-cards">
          <Link to={{ pathname: `/place/` + elem.tokenId }}>
            <div class="card">
              <div className="card-img-top-div ">
                <span className=" w-100 text-left set-text-font">{elem?.label?.substring(0, 13)}{elem?.label?.length > 13 && '(...)'}.{elem?.tld}</span>
              </div>
              <div class="card-body-1">
                <div class="card-style-2">

                  {/* {elem?.Auction === true && ( */}
                    <>
                      <h5 class="card-heading-1">Auction Ends</h5>
                      <p class="card-heading-2 justify-content-start">
                        {true && (
                          <Countdown
                            date={'Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)'}
                            renderer={({ days, hours, minutes, seconds }) => {
                              return (
                                <>
                                  <h5 class="card-heading-4">
                                    {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
                                  </h5>
                                </>
                              );
                            }}
                          ></Countdown>
                        )}
                      </p>
                    </>
                    {/* // )} */}
                </div>
                <div class="card-style-2">
                  <h5 class="card-heading-1">Highest Bid</h5>
                  <p class="card-heading-2 text-lowercase">
                    {/* <img
                      class="img-fluid mr-1"
                      src="\assets\eth-icon.svg"
                      alt="img"
                    /> */}
                    {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                 <span className='text-truncate'>{displayAmount(elem?.amount) || "0"}</span>

                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    });
    return (
        <>
        <div className="liveAuc">
          <section className="wrappper-market liveauction market">
                <div className="custom-containerl">
                    <div className="top-heading d-flex justify-content-between flex-wrap ml-3">
                        <h5>Hottest auctions 🔥 </h5>
                       <Link to='/marketplace'> <button className='button-hedaerrrrr'>View All <img src="\assets\newlanding-assets\arrow-right-new.svg" alt="img" className='img-fluid ml-2' /></button></Link>
                    </div>
                    <div class="cardsss">
                          {(display ? display : "")}

                        </div>
                        {loader &&
            <div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
                  <img
                    width={35}
                    style={{
                      // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                    }}
                    src="\loader\loader.gif"
                    alt="loader"
                  />
                </div>
                }
                </div>
                    
            </section>
        </div>
            
        </>
    )
}

export default LiveAuction


// {allNFTS?.map((elem) => {
//     // console.log('dsfjlasjfl', elem)
//     return (

//         <div className="col-xl-3 col-lg-4 col-md-6 col-12">
//             <Link to={{ pathname: `/place/` + elem.tokenId }}>
//                 <div class="card">
//                     <div className="card-img-top-div">
//                         {elem?.label?.substring(0, 37)}{elem?.label?.length > 37 && '(...)'}.{elem?.tld}
//                     </div>
                    
//                     {/* <div class="card-body-1">
//                         <div class="card-style-2">
//                             {elem?.Auction === false && <>
//                                 <h5 class="card-heading-1">Auction </h5>
//                                 <h5 class="card-heading-1 font-weight-bold text-dark">Ended </h5>
//                             </>}

//                             {elem?.Auction === true && (
//                                 <>
//                                     <h5 class="card-heading-1">Auction Ends</h5>
//                                     <p class="card-heading-2 justify-content-start">
//                                         {elem?.Timeline?.expiryTime && (
//                                             <Countdown
//                                                 date={elem?.expiryTime}
//                                                 renderer={({ days, hours, minutes, seconds }) => {
//                                                     return (
//                                                         <>
//                                                             <h5 class="card-heading-4">
//                                                                 {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
//                                                             </h5>
//                                                         </>
//                                                     );
//                                                 }}
//                                             ></Countdown>
//                                         )}
//                                     </p>
//                                 </>)}
//                         </div>
//                         <div class="card-style-2">
//                             <h5 class="card-heading-1">Highest Bid</h5>
//                             <p class="card-heading-2 text-lowercase">
//                                 <img
//                                     class="img-fluid mr-1"
//                                     src="\assets\eth-icon.svg"
//                                     alt="img"
//                                 />
//                                 {elem?.HighestBid
//                                     ? parseInt(elem?.HighestBid).toFixed(4) /
//                                     1000000000000000000 || 70
//                                     : "70"}{" "}

//                             </p>
//                         </div>
//                     </div> */}
//                 </div>
//             </Link>
//         </div>

//     )
// })}