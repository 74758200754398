import React, { useEffect, useState } from 'react'
import Navbar from '../landing/header/Navbar'
import "./commission.scss"
import DataTable from './DataTable'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Environment from '../../utils/Environment'
import axios from 'axios'
import Loader from '../../hooks/loader'
import Commision from '../../hooks/dataFetchers/commision'
import useWeb3 from '../../hooks/useWeb3'
import MinterMultipleClaimReward from '../../hooks/dataSenders/minterMultipleClaimReward'
import Modal from 'react-bootstrap/Modal';
const Commission = () => {
  const [loader, setLoader] = useState(false)
  const { account } = useWeb3React()
  const web3 = useWeb3();
  const [commision, setCommision] = useState(0)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [mintedData, setMintedData] = useState()
  const [additionalDataArr, setAdditionalDataArr] = useState([])
  const [availableCommision, setAvaiCommision] = useState(0)
  const [apiStatus, setApiCallStatus] = useState(false)
  const [multiIds, setMultiIds] = useState([])
  const { ClaimMultipleCommison } = MinterMultipleClaimReward()
  const [LastCommisionClaimed, setLastCommisionClaimed] = useState(0)
  const { currCommision } = Commision()
  // console.log('alsdfladddd', multiIds)
  const getAdditionalData = async (ids) => {
    setLoader(true)
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
        query MyQuery {
        auctionCreateds(where: {partnerId_in: [${ids}]}) {
        id
        tokenId
        minter
        mintAmount
        startTime
        expiryTime
        label
        labelhash
        tld
        isClaimed
        partnerId
      }
    }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log('additional data', response, ids)
          setAdditionalDataArr(response?.data?.data?.auctionCreateds)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getMinteddata = () => {
    setLoader(true)
    var config = {
      method: "get",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft/${Environment.partnerNFT}?chain=eth&format=decimal`,
      headers: {
        "x-api-key":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZlMGI5MjNkLTNkYmYtNDBlZC04MGY4LTU3NWVhODEwYjc0ZiIsIm9yZ0lkIjoiMjA3ODg5IiwidXNlcklkIjoiMjA3NTYxIiwidHlwZUlkIjoiZWMwMWYzMDctNTJhMS00ZTNiLWExYzgtNWM3MmQwOGExZDc1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODczNTcwNDYsImV4cCI6NDg0MzExNzA0Nn0.Uv0uw5Q_lnSgXMquIJ0GHKlx_uNMkdDptrT00MzmwPo",
      },
    };
    axios(config)
      .then(function (response) {
        setLoader(true)
        // console.log('partner nfts reward', response)
        let minted = response.data.result;
        // console.log('tttytytyty ==== ', minted)
        let partnerNftsIds = minted?.map((item) => parseInt(item?.token_id))
        // console.log('tttytytyty partnerNftsIds ', partnerNftsIds)
        getAdditionalData(partnerNftsIds)
        getTotalClaimed(partnerNftsIds)
        // console.log('partnerNft', minted, partnerNftsIds)
        setMintedData(minted)
        setLoader(false)
      })
      .catch(function (error) {
        // console.log('partnerNft', error)
        // // console.log(error);
        setLoader(false)
      });
  };
  const getTotalClaimed = async (ids) => {
    if (account) {
      setLoader(true)
      var data = JSON.stringify({
        query: `
        query MyQuery {
          minterClaims(where: {partnerId_in: [${ids}]}) {
            tokenId
            id
            amount
          }
        }`,
        variables: {},
      });
      var config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // console.log('total claimed', response)
          let claimedCommison = response?.data?.data?.minterClaims
          // console.log('tttytytyty',claimedCommison)
          var totalMintAmount = 0;
          for (var i = 0; i < claimedCommison.length; i++) {
            let weiAmount = web3.utils.fromWei(claimedCommison[i]?.amount, 'ether')
            totalMintAmount += parseFloat(weiAmount);
          }
          setLastCommisionClaimed(totalMintAmount)
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
        });
    }
  };
  const getTotalCommison = async () => {
    var totalMintAmount = 0;
    let dumArr=[]
    for (var i = 0; i < mintedData.length; i++) {
      try {
        const commision = await currCommision(mintedData[i]?.token_id,)
        if(commision > 0){
          dumArr.push(parseInt(mintedData[i]?.token_id))
        }
        var mintAmount = parseFloat(commision);
        // console.log('first', commision)
      } catch (error) {
        var mintAmount = parseFloat(0);
      }
      totalMintAmount += mintAmount;
    }
    setMultiIds(dumArr)
    setAvaiCommision(totalMintAmount)
  }
  const claimCommisionValue = async () => {

    try {
      setLoader(true)
      const arr = Array.from(Object.values(multiIds));
      const claimed = await ClaimMultipleCommison(arr, account)
      handleShow()
      setApiCallStatus(!apiStatus)
      setLoader(false)
    } catch (error) {
      handleShow1()
      // console.log('first', error)
      setLoader(false)
    }

  }
  useEffect(() => {
    if (mintedData) {
      getTotalCommison()
    }
  }, [mintedData, apiStatus])
  useEffect(() => {
    if (account) {
      getMinteddata()
    }
  }, [account, apiStatus])
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      <section className="mycommission-section">
        <div className="custom-container">
          <Link to="/" className="back-arrow"><img src="\assets\arrow-back.svg" alt="img" className='img-fluid' />Back</Link>
          <div className="main-heading">
            <h6>My Partner Claims</h6>
          </div>
          <div className="twice-div">
            <div className="inner-item">
              <h6>Available For Claim</h6>
              <h5>{availableCommision?.toString()?.length > 6 ? parseFloat(availableCommision)?.toFixed(2) : availableCommision} QUECKO</h5>
            </div>
            <div className="inner-item">
              <h6>Total Claimed</h6>
              <h5>{LastCommisionClaimed?.toString()?.length > 6 ? parseFloat(LastCommisionClaimed)?.toFixed(2) : LastCommisionClaimed} QUECKO</h5>
            </div>
          </div>
          <div className="bottom-part">
            <div className="upper-area">
              <h6>My Partner Claims History</h6>
              <button disabled={multiIds?.length === 0} className={multiIds?.length > 0 ? 'btn-claim' : 'btn-claim btnDis px-3'} onClick={claimCommisionValue}>{multiIds?.length === 0 ? 'All Claimed' : 'Claim All'}</button>
              {/* <button className='btn-claim' onClick={claimCommisionValue}>Claim All</button> */}
            </div>
            <DataTable mintedData={mintedData} setLoader={setLoader} setApiCallStatus={setApiCallStatus} apiStatus={apiStatus} additionalDataArr={additionalDataArr} />
          </div>
        </div>
      </section>
      <Modal className='success-modal' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\success2.svg" alt="img" className='img-fluid' />
            <h6> Claimed Successfully</h6>
          </div>
          <button onClick={handleClose} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>

      <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>claim commissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
            <h6>Failed to claim commission. try again</h6>
          </div>
          <button onClick={handleClose1} className='btn-claim'>Okay</button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Commission
