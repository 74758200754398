import React from 'react'
import './paypal.scss'
function Paypal() {
    return (
        <section className="paypal-main">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-xl-11 col-lg-11 m-auto p-0">
                        <div className="row">
                            <div className="col-md-8 mx-auto p-0">
                                <div className="order-summary-venmo text-center">
                                    <div className="payHeading">
                                        <h2>Order summary</h2>
                                        <p>See order details</p>
                                    </div>
                                    <div className="paypal-due">
                                        <p>Total Due</p>
                                        <p>$260.00</p>
                                    </div>
                                </div>
                                <div className="row btnRow">
                                    <div className="col-md-6 pl-0">
                                        <button className='img-btn w-100'><img src="\chekout\paypalWhite.svg" alt="" /> Buy Now</button>
                                    </div>
                                    <div className="col-md-6 pr-0 mt-md-0 mt-4">
                                        <button className='img-btn w-100'><img className='img-fluid' src="\chekout\venmo.svg" alt="" /> Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Paypal