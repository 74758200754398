import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";

export const CashBack = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.market;
  const contract = getMarketPlaceContract(tokenAddress, web3);
  const currCashBack = useCallback(async (tokenId) => {
    const approved = await contract.methods.getCashbackReward(tokenId).call();
    let appVal = web3.utils.fromWei(approved, 'ether')
    return appVal;
  }, [contract]);

  return { currCashBack: currCashBack };
};

export default CashBack;
