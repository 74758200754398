import React, { useState, useEffect } from "react";
import "./market.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "../../hooks/loader";
import CheckExpire from "../../hooks/dataFetchers/checkExpiry";
import CheckHigh from "../../hooks/dataFetchers/getHighestBid";
import ClaimNft from "../../hooks/dataFetchers/checkClaim";
import Countdown from "react-countdown";
import Navbar from "../landing/header/Navbar";
import Environment from "../../utils/Environment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useWeb3React } from "@web3-react/core";
import { useRef } from 'react';
const Market = ({ setAuctionLength, disLanding }) => {
  const { account } = useWeb3React()
  const [allNFTS, setAllNFTS] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [metaDataSearch, setMetaDataSearch] = useState([]);
  const [heightState, setHeightState] = useState(0);
  const [error, setError] = useState()
  const inputRef = useRef(null);
  const [start, setStart] = useState(0);
  const [tabM, setTabM] = useState('')
  const [search, setSearch] = useState(null);
  const [search2, setSearch2] = useState(null);
  const [filter, setFilter] = useState(false);
  const [sortByy, setSortByy] = useState('all')
  const { CheckExpiry } = CheckExpire();
  const { HighestBid } = CheckHigh();
  const { CheckClaimNft } = ClaimNft();
  const getMinted = async () => {
    try {
      setLoader(true);
      const data = JSON.stringify({
        query: `query MyQuery {
          auctionCreateds(orderBy: startTime, orderDirection: desc, first: 1000) {
            expiryTime
            id
            isClaimed
            label
            labelhash
            mintAmount
            minter
            startTime
            tld
            tokenId
          }
        }`,
        variables: {},
      });

      const config = {
        method: "post",
        url: Environment.marketplaceGraph,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      const minted = response?.data?.data?.auctionCreateds;
      // console.log('first', minted)
      if (minted) {
        setAllNFTS(minted);
      }
    } catch (error) {
      // Handle error here
    } finally {
      setLoader(false);
    }
  };

  const getSearchData = async () => {
    if (search2) {
      try {
        setLoader3(true);
        const data = JSON.stringify({
          query: `query MyQuery {
            auctionCreateds(orderBy: startTime, orderDirection: desc, where: {label_contains: "${search2}"}) {
              expiryTime
              id
              isClaimed
              label
              labelhash
              mintAmount
              minter
              startTime
              tld
              tokenId
            }
          }`,
          variables: {},
        });

        const config = {
          method: "post",
          url: Environment.marketplaceGraph,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);
        const minted = response?.data?.data?.auctionCreateds || [];
        // console.log('dlsafjlasldf', minted);

        if (minted.length > 0) {
          const metaDataPromises = minted.map(async (item) => {
            const res1 = await CheckExpiry(item.tokenId);
            const dat = new Date(0);
            dat.setUTCSeconds(parseFloat(res1?.expiryTime));
            const data = {
              tokenId: item.tokenId,
              HighestBid: '',
              Auction: '',
              Timeline: '',
              name: item.label,
              mintTime: item.mintTime,
              initialExpiryTime: dat,
              tld: item.tld,
            };

            const res0 = await HighestBid(item.tokenId);
            data.HighestBid = await res0?.amount;

            const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
            res1.expiryTime = dateVal;
            const date0 = new Date();
            data.Auction = dat > date0;

            data.Timeline = await res1;
            return data;
          });

          const metaDataSearch = await Promise.all(metaDataPromises);
          setMetaDataSearch(metaDataSearch);
        } else {
          setError(true);
          setMetaDataSearch([]);
        }
      } catch (error) {
        // Handle error here
      } finally {
        setLoader3(false);
      }
    } else {
      if (search2 === '') {
        setMetaData([]);
        setAllNFTS([]);
        setitemlength(heightState);
        setTimeout(() => {
          getMinted();
        }, 2000);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      getSearchData();
    }
  };
  const OnAuction = () => {
    setFilter(true)
    setAllNFTS([])
    setLoader(true);
    setLoader2(true)
    // setMyFilterData('On Auction')

    var data = JSON.stringify({
      query: `query MyQuery {
  auctionCreateds(orderBy: mintAmount, orderDirection: desc ) {
    expiryTime
    id
    isClaimed
    label
    labelhash
    mintAmount
    minter
    startTime
    tld
    tokenId
  }
}`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        setLoader(true);
        setLoader2(true)
        var dumAuction = [];
        let minted = response?.data?.data?.auctionCreateds;

        // minted.sort(function (a, b) {
        //   return parseInt(b.tokenId) - parseInt(a.tokenId);
        // });
        for (let nft of minted) {
          let resauctioned
          try {
            resauctioned = await CheckExpiry(nft?.tokenId);
          } catch (error) {
          }

          let dat = new Date(0);
          dat.setUTCSeconds(parseFloat(resauctioned?.expiryTime));
          let dateVal = null;
          if (dat) {
            const date0 = new Date();
            if (dat > date0) {
              dumAuction.push(nft);
            }
          }
        }
        if (disLanding === 'd-none') {
          setAuctionLength(dumAuction)
        }
        // console.log('hhhhhhhhhhggggggggg', dumAuction)
        setAllNFTS(dumAuction);
        setLoader(false);
        setLoader2(false)
      })
      .catch((err) => {
        // console.log('hhhhhhhhhhggggggggg', err)
        setLoader(false);
        setLoader2(false)
      });
    setLoader(true);
    setLoader2(true)
  };

  const OnSold = async () => {
    setAllNFTS([])
    setFilter(true)
    setLoader(true);
    setLoader2(true)
    // setMyFilterData('Sold')
    var data = JSON.stringify({
      query: `query MyQuery {
  auctionCreateds {
    expiryTime
    id
    isClaimed
    label
    labelhash
    mintAmount
    minter
    startTime
    tld
    tokenId
  }
}`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        setLoader(true);
        setLoader2(true)
        let minted = response?.data?.data?.auctionCreateds;
        minted.sort(function (a, b) {
          return parseInt(b.tokenId) - parseInt(a.tokenId);
        });
        var dumSold = [];
        for (let nft of minted) {
          const res = await CheckClaimNft(nft?.tokenId);
          var sold = res?.isClaimed;
          if (sold) {
            dumSold.push(nft);
          }
        }
        setAllNFTS(dumSold);

        setLoader(false);
        setLoader2(false)
      })
      .catch((err) => {
        setLoader(false);
        setLoader2(false)
      });
    setLoader(true);
    setLoader2(true)
  };

  const UnSold = async () => {
    setAllNFTS([])
    setLoader(true);
    setLoader2(true)
    setFilter(true)
    // setMyFilterData('UnClaimed')
    var data = JSON.stringify({
      query: `query MyQuery {
  auctionCreateds {
    expiryTime
    id
    isClaimed
    label
    labelhash
    mintAmount
    minter
    startTime
    tld
    tokenId
  }
}`,
      variables: {},
    });
    var config = {
      method: "post",
      url: Environment.marketplaceGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(async (response) => {
        setLoader(true);
        setLoader2(true)
        let minted = response?.data?.data?.auctionCreateds;
        var dumSold = [];
        for (let nft of minted) {
          if (nft?.minter?.toLowerCase() === account?.toLowerCase()) {
            const res = await CheckClaimNft(nft?.tokenId);
            let res1 = await CheckExpiry(nft?.tokenId);
            let dat = new Date(0);
            dat.setUTCSeconds(parseFloat(res1?.expiryTime));
            if (res1?.expiryTime) {
              const date0 = new Date();
              if (!(dat > date0)) {
                if (res) {

                  var sold = res?.isClaimed;
                  if (sold === false) {
                    dumSold.push(nft);
                  }
                }
              }
            }
          }
        }
        setAllNFTS(dumSold);
        setLoader(false);
        setLoader2(false)
      })
      .catch((err) => {
        setLoader(false);
        setLoader2(false)
      });
    setLoader(false);
    setLoader2(false)
  };



  const handleSearchnew = async (e) => {
    // setSearch(e.target.value);
    setError('')
    setSearch2(e.target.value?.toLowerCase())
    setLoader(false)
    if (e.target.value === '') {
      setMetaDataSearch([])
    }
  };
  const calculateHeight = () => {
    const width = window.innerWidth;

    if (width >= 3000 || width >= 2560) {
      return 24;
    } else if (width >= 1700 || width < 2151) {
      return 12;
    } else if (width >= 1600 || width < 1681) {
      return 12;
    } else {
      return 8;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
    setLoader(true)
    setTimeout(() => {
      const height = calculateHeight();
      setitemlength(parseInt(height))
      setHeightState(height);
      setLoader(true)
    }, 500);


  }, []);
  useEffect(() => {
    if (disLanding === 'd-none') {
      OnAuction()
    } else {
      getMinted();
    }
  }, []);
  const [itemlength, setitemlength] = useState(heightState);
  // console.log('alsdjflasjfl', itemlength, allNFTS?.length, metaData?.length)
  const LoadMoreItems = async () => {
    const a = itemlength + heightState;
    if (a <= allNFTS?.length) {
      setStart(itemlength);
      setitemlength(a);
    } else {
      setitemlength(allNFTS?.length);
    }
  };
  const allldata = async () => {
    if (itemlength < 9) {
      setLoader2(true);
    }

    try {
      setLoader(true);
      if (allNFTS?.length > 0) {
        const t = start;
        const endIndex = Math.min(allNFTS?.length, itemlength);
        const itemsToProcess = allNFTS.slice(t, endIndex);
        const metaDataPromises = itemsToProcess.map(async (item) => {
          const res1 = await CheckExpiry(item.tokenId);
          const dat = new Date(0);
          dat.setUTCSeconds(parseFloat(res1?.expiryTime));
          const data = {
            tokenId: item.tokenId,
            HighestBid: '',
            Auction: '',
            Timeline: '',
            name: item.label,
            mintTime: item.mintTime,
            initialExpiryTime: dat,
            tld: item.tld,
          };

          const res0 = await HighestBid(item.tokenId);
          data.HighestBid = await res0?.amount;

          const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
          res1.expiryTime = dateVal;
          const date0 = new Date();
          data.Auction = dat > date0;

          data.Timeline = await res1;
          return data;
        });

        const processedData = await Promise.all(metaDataPromises);
        setMetaData([...metaData, ...processedData]);
      }
    } catch (error) {
      // Handle error here
    } finally {
      setLoader2(false);
      setLoader(false);
    }
  };
  const Filterdata = async () => {
    try {
      setLoader(true);
      setMetaData([]);

      if (allNFTS?.length > 0) {
        const endIndex = Math.min(allNFTS.length, itemlength);
        const itemsToProcess = allNFTS.slice(0, endIndex);

        const metaDataPromises = itemsToProcess.map(async (item) => {
          const res1 = await CheckExpiry(item.tokenId);
          const dat = new Date(0);
          dat.setUTCSeconds(parseFloat(res1?.expiryTime));

          const data = {
            tokenId: item.tokenId,
            HighestBid: '',
            Auction: false,
            Timeline: '',
            name: item.label,
            mintTime: item.mintTime,
            initialExpiryTime: dat,
            tld: item.tld,
          };

          const res0 = await HighestBid(item.tokenId);
          data.HighestBid = await res0?.amount;

          const dateVal = new Date(parseInt(res1.expiryTime) * 1000);
          res1.expiryTime = dateVal;
          const date0 = new Date();
          data.Auction = dat > date0;

          data.Timeline = await res1;
          return data;
        });

        const processedData = await Promise.all(metaDataPromises);
        // const bidDesc = processedData.sort((a, b) => parseInt(b.HighestBid) - parseInt(a.HighestBid));
        setMetaData(processedData);
      }
    } catch (error) {
      // Handle error here
    } finally {
      setLoader(false);
    }
  };


  useEffect(() => {
    if (filter) {
      Filterdata()
    } else {
      allldata()
    }
  }, [allNFTS?.length > 0, itemlength]);
  // Card component to render each card
  const Card = ({ elem }) => {
    return (
      <div className="custom-cards">
        <Link to={{ pathname: `/place/${elem.tokenId}` }}>
          <div className="card">
            <div className="card-img-top-div">
              <span className="w-100 text-left set-text-font">
                {elem.name?.substring(0, 13)}{elem.name?.length > 13 && '(...)'}.{elem.tld}
              </span>
            </div>
            <div className="card-body-1">
              <div className="card-style-2">
                {elem.Auction === false && (
                  <>
                    <h5 className="card-heading-1">Auction</h5>
                    <h5 className="card-heading-1 font-weight-bold text-dark">Ended</h5>
                  </>
                )}
                {elem.Auction === true && (
                  <>
                    <h5 className="card-heading-1">Auction Ends</h5>
                    <p className="card-heading-2 justify-content-start">
                      {elem.Timeline?.expiryTime && (
                        <Countdown
                          date={elem.initialExpiryTime}
                          renderer={({ days, hours, minutes, seconds }) => {
                            return (
                              <>
                                <h5 className="card-heading-4">
                                  {days < 10 ? '0' : ''}{days} : {hours < 10 ? '0' : ''}{hours} : {minutes < 10 ? '0' : ''}{minutes} : {seconds < 10 ? '0' : ''}{seconds}
                                </h5>
                              </>
                            );
                          }}
                        ></Countdown>
                      )}
                    </p>
                  </>
                )}
              </div>
              <div className="card-style-2">
                <h5 className="card-heading-1">Highest Bid</h5>
                <p className="card-heading-2 text-lowercase">
                  {/* <img className="img-fluid mr-1" src="\assets\eth-icon.svg" alt="img" /> */}
                  {elem.HighestBid
                    ? (parseInt(elem.HighestBid) / 1000000000000000000)?.toFixed(2)
                    : "70"}{" "}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const display = (search2 ? metaDataSearch : (disLanding === 'd-none' ? metaData?.slice(0, 8) : metaData))
    ?.filter((elem) => {
      if (search === null) {
        return true;
      } else {
        return elem?.name?.toLowerCase().includes(search.toLowerCase());
      }
    })
    .slice(0, search2 ? 1000 : itemlength)
    ?.map((elem, index) => <Card key={index} elem={elem} />);



  const fetchMoreData = () => {
    if (metaData?.length >= allNFTS?.length) {
      return false;
    } else {
      return true;
    }
  };


  return (
    <>
      {loader3 && <Loader />}
      <div className="wrappper-market">

        <Navbar disLanding={disLanding} setTab={''} setTab2={''} setTab3={'common'} />
        <section class="market">
          <div class="custom-container">
            <div class="row">
              <div class="col-sm-12 col-12 m-auto p-0 ">
                <div className={`domainSearh ${disLanding} mb-5`}>
                  <div className="pspans">
                    <span
                      type="button" onClick={() => { setSortByy('all'); setTabM('') }}
                    >
                      {(loader2 || loader) ? <p className={sortByy === 'all' ? "sortcolr" : 'cNAllowed'}>All</p> : <p className={sortByy === 'all' ? "sortcolr" : 'cPointer'}>All</p>}
                    </span>
                    {/* //when you want to call OnAuction() OnAuction() */}
                    <span
                      type="button"

                      onClick={() => { (loader2 || loader) || setSortByy('auction'); setTabM('') }}
                    >
                      {(loader2 || loader) ? <p className={sortByy === 'auction' ? "sortcolr" : 'cNAllowed'}> On Auction</p> : <p className={sortByy === 'auction' ? "sortcolr" : 'cPointer'}> On Auction</p>}
                    </span>
                    <span
                      type="button"
                      //when you want to call onsold OnSold();
                      onClick={() => { (loader2 || loader) || setSortByy('sold'); setTabM('sold'); setLoader(false) }}
                    >
                      {(loader2 || loader) ? <p className={sortByy === 'sold' ? "sortcolr" : 'cNAllowed '}>Sold</p> : <p className={sortByy === 'sold' ? "sortcolr" : 'cPointer '}>Sold</p>}


                    </span>
                    {/* //when you want to call UnSold UnSold(); */}
                    <span
                      type="button"

                      onClick={() => { (loader2 || loader) || setSortByy('unclaimed'); setTabM('sold'); setLoader(false) }}
                    >
                      {(loader2 || loader) ? <p className={sortByy === 'unclaimed' ? "sortcolr" : 'cNAllowed'}>Unclaimed</p> : <p className={sortByy === 'unclaimed' ? "sortcolr" : 'cPointer'}>Unclaimed</p>}
                    </span>
                  </div>
                  <div style={{ position: "relative" }}>
                    <input type="text" value={search2} onKeyDown={handleKeyDown}
                      ref={inputRef}
                      onChange={handleSearchnew} className="search-bar" placeholder="Search Domain" />
                    <img
                      onClick={getSearchData}
                      className="img-fluid sImg searc-icon"
                      src="\assets\search-normal.svg"
                      alt="img"
                    />
                  </div>
                </div>
                {tabM === 'sold' ?
                  <>
                    <div className={`centershowing-text text-center w-100 ${disLanding} py-3`}>
                      <h2 >Domains Not Found <span className="common">!</span></h2>
                    </div>
                  </>
                  :

                  (
                    search2 ?
                      <>
                        <div class="cardsss">
                          {(display ? display : "")}

                        </div>
                        {
                          (display?.length < 1 && !loader2 && !loader) &&
                          <div className={`centershowing-text text-center w-100 ${disLanding} py-3`}>
                            {error && <h2 >Domains Not Found <span className="common">!</span></h2>}
                          </div>
                        }
                      </>
                      :
                      <InfiniteScroll
                        dataLength={itemlength}
                        next={LoadMoreItems}
                        hasMore={fetchMoreData}
                      // scrollableTarget="scrollableDiv"
                      >
                        <div class="cardsss">
                          {(display ? display : "")}

                        </div>
                        {
                          (display?.length < 1 && !loader2 && !loader) &&
                          <div className={`centershowing-text text-center w-100 ${disLanding} py-3`}>
                            {metaDataSearch?.lenfth < 1 && <h2 >Domains Not Found <span className="common">!</span></h2>}
                          </div>
                        }

                      </InfiniteScroll>)
                }
                {loader && <div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
                  <img
                    width={35}
                    style={{
                      // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                    }}
                    src="\loader\loader.gif"
                    alt="loader"
                  />
                </div>}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Market;