import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getregistrarAddressContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { soliditySha3 } from 'web3-utils';
import namehash from 'eth-ens-namehash'
import { getBigNumber } from '../../utils/formatBalance'
const DomainProfileCont = () => {
    const { account } = useWeb3React()
    const web3 = useWeb3();
    const contractAddress = environment.registrarAddress;
    const contract = getregistrarAddressContract(contractAddress, web3);
    const domainProfileFun = useCallback(
        async (toAddress, tokeId) => {
            let tokenIdBigNO = web3.utils.toBN(tokeId).toString()
            let accountLowerCase = account.toLowerCase()
        // // console.log('transferFrom', accountLowerCase, toAddress, tokenIdBigNO,tokeId)
            try {
                var gasFunPrice;
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    gasFunPrice = result2.toString()
                })
                // console.log("gasfun", accountLowerCase, toAddress, tokenIdBigNO)
                // // console.log("web3.toBigNumber(totalSupply).toFixed():::::", web3.utils.toBN(tokeId).toString());
                const gas = await contract.methods.transferFrom(accountLowerCase, toAddress, tokenIdBigNO).estimateGas({ from: account, })

                const res = await contract.methods.transferFrom(accountLowerCase, toAddress, tokenIdBigNO).send({
                    from: account, gas: gas,
                    gasPrice: gasFunPrice,
                })
                return res
            } catch (error) {
                // // console.log('jwasd error', error)
                throw error
            }


        },
        [contract, account]
    );
    return { domainProfileFun: domainProfileFun };
};
export default DomainProfileCont;