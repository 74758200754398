import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import CashBack from '../../hooks/dataFetchers/cashback'
import CashBackClaimReward from '../../hooks/dataSenders/cashbackClaimReward'
import { useWeb3React } from '@web3-react/core';
import { API_URL } from '../../ApiUrl';
import axios from 'axios';

function TableRow({ item, setLoader, setApiCallStatus, apiStatus, claimIds, setClaimIds }) {
    const [format, setFormat] = useState()
    const [cashback, setCashBack] = useState(0)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { currCashBack } = CashBack()
    const { ClaimCashBack } = CashBackClaimReward()
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const { account } = useWeb3React()
    // console.log('first')
    const formatDate = () => {
        // Epoch time in seconds
        var epochTime = item?.startTime;

        // Create a new Date object with the Epoch time
        var date = new Date(epochTime * 1000);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = date.getMonth() + 1; // Months are zero-based, so add 1
        var day = date.getDate();

        // Format the components as a string in the desired format
        var formattedDate = `${day}/${month < 10 ? '0' : ''}${month}/${year}`;
        setFormat(formattedDate)
    }
    const getCommisionValue = async () => {
        try {
            const cashback = await currCashBack(item?.tokenId)
            setCashBack(cashback)
            // console.log('cashback',cashback)
            // console.log('first', cashback)
        } catch (error) {
            // console.log('first', error)
            setCashBack(0)
        }

    }

    const claimCommisionValue = async () => {
        try {
            setLoader(true)
            let tok = localStorage.getItem("accessToken");
            let data = {
                tokenIds: [item?.tokenId],
                walletAddress: account?.toLowerCase()
            }
            var config = {
                method: "post",
                url: `${API_URL}/compensation/get-cashback-claim`,
                data: data,
                headers: {
                    authorization: `Bearer ` + tok
                }
            };
            axios(config)
                .then(async function (response) {
                    let dumObj = response?.data?.data[0];
                    try {
                        const claimed = await ClaimCashBack(dumObj?.tokenId, dumObj?.v, dumObj?.r, dumObj?.s, account)
                        setClaimIds([]);
                        handleShow()
                    } catch (err) {
                        console.log(err)
                        handleShow1()
                    }
                    setLoader(false)
                }).catch(function (error) {
                    handleShow1()
                    // console.log('first', error)
                    setLoader(false)
                });
        } catch (error) {
            handleShow1()
            // console.log('first', error)
            setLoader(false)
        }

    }
    useEffect(() => {
        if (item) {
            formatDate()
            getCommisionValue()
        }

    }, [item])

    const CheckVal = (item) => {
        let arr = claimIds;
        let dumObj = arr?.find((i) => {
            return i?.tokenId === item?.tokenId;
        })
        if (dumObj) {
            arr = arr?.filter((i) => {
                return i?.tokenId !== item?.tokenId;
            })
        } else {
            arr?.push(item?.tokenId);
        }
        setClaimIds(arr);
    }

    return (
        <>
            <tr>
                <td>
                    <div className="custom-checkbox">
                        {parseFloat(cashback) !== 0 &&
                            <div class="form-group">
                                <input checked={claimIds?.find((i) => i === item?.tokenId)} onChange={(e) => CheckVal(item)} type="checkbox" id={item?.tokenId} />
                                <label for={item?.tokenId}></label>
                            </div>
                        }
                    </div>
                </td>
                <td>{format}</td>
                <td>{item?.label?.substring(0, 25) || '928393uxij'}{JSON.stringify(item?.label)?.length > 25 && '(...)'}.{item?.tld}</td>
                <td>{cashback > 0 ? (parseFloat(cashback)?.toString()?.length > 12 ? parseFloat(cashback)?.toFixed(12) : cashback) : cashback} TOMI</td>
                <td style={{ textAlign: "right" }}>
                    {/* <button disabled={true} className={ 'btn-claim btnDis px-3'} onClick={claimCommisionValue}>{parseFloat(cashback) === 0 ? 'Claimed' : 'Claim'}</button> */}
                    <button disabled={parseFloat(cashback) === 0} className={parseFloat(cashback) > 0 ? 'btn-claim' : 'btn-claim btnDis px-3'} onClick={claimCommisionValue}>{parseFloat(cashback) === 0 ? 'Claimed' : 'Claim'}</button>
                </td>
            </tr>
            <Modal className='success-modal' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\success2.svg" alt="img" className='img-fluid' />
                        <h6> Claimed Successfully</h6>
                    </div>
                    <button onClick={handleClose} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>

            <Modal className='success-modal' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton>
                    <Modal.Title>claim commissions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="main-content">
                        <img src="\assets\error-icon.svg" alt="img" className='img-fluid' />
                        <h6>Failed to claim commission. try again</h6>
                    </div>
                    <button onClick={handleClose1} className='btn-claim'>Okay</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TableRow