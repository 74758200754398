import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import DomainProfile from '../../hooks/dataSenders/websiteHash'
function Website({ UrlData, setLoader }) {
  const [copied, setCopied] = useState(false)
  const [suggestion, setSuggestion] = useState()
  const [cryptoData, setCryptoData] = useState({
    IpfsHash: '',
    sitetype: ''
  })
  // console.log('wajeorrvejwt o5ertdv',UrlData)
  let dis = false
  const ipTest = RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$')
  // // console.log('ytfvbj9uytrfvbnm', ipTest.test('192.168.0.250'))
  const { domainProfileFun } = DomainProfile()
  const profileDataFun = async (e) => {
    var { name, value } = e.target;
    let values = value.replace(/\s/g, "")
    setCryptoData((pre) => {
      return { 
        ...pre, [name]: values
      }
    })
    if (parseInt(value) === 1) {
      setSuggestion({
        IpfsHash: 'Ipfs hash',
        sitetype: 'QmNp3YSYupKnxtp5xTrRx4...'
      })
    } else if (parseInt(value) === 2) {
      setSuggestion({
        IpfsHash: 'Ip Address',
        sitetype: '192.168.0.53'
      })
    } else if (parseInt(value) === 3) {
      setSuggestion({
        IpfsHash: 'Dns Domain',
        sitetype: 'google.com , tomi.com'
      })
    } else if (parseInt(value) === 4) {
      setSuggestion({
        IpfsHash: 'Onion',
        sitetype: '3g2upl4pq6kufc4m...'
      })
    }

  }
  // // console.log('ddff', cryptoData)
  const confirCryptoChanges = async (e) => {
    if (cryptoData.IpfsHash === '' || cryptoData.sitetype === '') {
      toast.error('Fill the fields first', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    } else if (!UrlData) {
      toast.error('TokenId is missing', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
    else if (UrlData) {
      let jsonData = JSON.stringify(cryptoData.IpfsHash)
      try {
        setLoader(true)
        const res = await domainProfileFun(UrlData, cryptoData.IpfsHash, cryptoData.sitetype, false)
        toast.success('Mapping Successfull', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        // // console.log('domainProfile contract data succ', res)
        setLoader(false)
      } catch (error) {
        // // console.log('domainProfile contract data err', error)
        toast.error(error?.message?.split("message\":\"")[1]?.split("}")[0]?.split(`"`)[0]?.toUpperCase() || error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
        setLoader(false)
      }

    } else {
      // toast.error('dont know')
    }

  }
  const settime = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }
  const getProfileData = async (e) => {

    if (UrlData) {
      try {
        setLoader(true)
        const res = await domainProfileFun(UrlData, '00', 0, true)
        // // console.log('res of the hassh', res)
        setLoader(false)
        let types;
        if (res?.codec == "ipfs-ns") {
          types = 1;

        } else if (res?.codec == "ip4") {
          types = 2;
        } else if (res?.codec == "dns") {
          types = 3;
        } else {
          types = 4;
        }
        // // console.log('res of the hassh', types)
        if(res?.codec == "onion"){
          setCryptoData({
            IpfsHash: res?.domainDecoded?.split('.')[0],
            sitetype: types
          })
        }else{
           setCryptoData({
          IpfsHash: res?.domainDecoded,
          sitetype: types
        })
        }
       

      } catch (error) {
        setLoader(false)
        // // console.log('domainProfile contract data err', error)
        // toast.error('Profile Data get Error')
      }

    } else {
      // toast.error('dont know')
    }

  }
  useEffect(() => {
    getProfileData()
  }, [UrlData])
  return (
    <>
      <div className="website">
        <div className="top">
          <h5>Website Launched</h5>
        </div>
        <div className="option-field">
          <div className="d-flex justify-content-between align-items-centesr">
            {/* <label>IPFS Hash</label> */}
            <select value={cryptoData.sitetype} onChange={profileDataFun} name='sitetype' class={cryptoData.sitetype ? "custom-select w-25 mb-3 " : "ccl custom-select w-25 mb-3 "} id="inputGroupSelect02">
              <option selected>Select Type</option>
              <option value="1">IPFS Hash</option>
              <option value="2">Ip Address</option>
              <option value="3">ICANN Domain</option>
              <option value="4">Onion</option>
            </select>
          </div>
         <div style={{position: "relative"}}>
         <input value={cryptoData.IpfsHash} name='IpfsHash' onChange={profileDataFun} type="text" placeholder={`Enter ${suggestion?.IpfsHash}`} />
          {!copied ?
            <CopyToClipboard text={cryptoData?.IpfsHash}
              onCopy={() => { setCopied('Copied'); settime() }}>
              <img
                src="\assets\copy.svg"
                alt="img"
                className="img-fluid copy cPointer"
              />
            </CopyToClipboard>
            : <p className=" copy common">Copied</p>}
         </div>
          {/* {(cryptoData.sitetype && cryptoData?.IpfsHash && parseInt(cryptoData.sitetype) === 3) && ((parseInt(cryptoData.sitetype) === 3 && (cryptoData?.IpfsHash?.split('.')[cryptoData?.IpfsHash?.split('.')?.length - 1])?.length < 5 && (cryptoData?.IpfsHash?.split('.')[cryptoData?.IpfsHash?.split('.')?.length - 1])?.length > 0) || (dis = true && <p className='text-danger font-weight-bold'>Invalid Dns Domain Link</p>))} */}
          {/* {(cryptoData.sitetype && cryptoData?.IpfsHash && parseInt(cryptoData.sitetype) === 2) && ((parseInt(cryptoData.sitetype) === 2 && ipTest.test(cryptoData?.IpfsHash)) || (dis = true && <p className='text-danger font-weight-bold'>Invalid Ip Address</p>))} */}

          {/* <img
            src="\assets\copy.svg"
            alt="img"
            className="img-fluid copy"
          /> */}
          {suggestion && <>
            <h5 className='mt-4 sugg'>Suggestion</h5>
            <p className='py-3 suggP'>Your <span className='font-weight-bold px-1'> {suggestion?.IpfsHash}</span> must be in this form eg: <span className='common px-1'>{suggestion?.sitetype}</span></p>
          </>}
        </div>

        <div className="bottom-btn">
          <button disabled={dis} className={dis && 'bg-secondary cursorNA'} onClick={confirCryptoChanges}>Confirm</button>
          <button onClick={() => setCryptoData({ IpfsHash: '', sitetype: '' })}>Remove</button>
        </div>
      </div>
      {/* <div className="spacetop">

      </div> */}
      {/* <div className="website webisteUpload">

        <div className="top">
          <h5>Uplaod Files to IPFS</h5>
          <p>*Limit 20MB per domain</p>
        </div>
        <div className="uploadfiles-div">
          <p>Drag ‘n’ drop files here</p>
          <p>or</p>
          <div className="row w-100">
            <div className="col-xl-4 col-lg-5 col-sm-7 mx-auto">
              <button className='d-flex w-100 align-items-center justify-content-center'><img src="\DProfile\send-square.svg" className='img-fluid pr-2' alt="" /> Upload Files</button>

            </div>
          </div>

        </div>
        <div className="bottom-btn">
          <button className='d-flex align-items-center justify-content-center'><img src="\DProfile\send-square.svg" className='img-fluid pr-2' alt="" /> Upload to IPFS</button>
          <button>Cancel</button>
        </div>
      </div> */}
    </>
  )
}

export default Website