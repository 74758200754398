import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
const Footer = () => {
    return (
        <>
            <section className="main-footer ">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-xl-11 col-lg-11 m-auto ">
                            <nav className="navbar  navbar-expand-xl ">

                                <a href="#" className="navbar-brand ">
                                    <Link to="/">
                                        <img src="\footernew-logo.svg" alt="img" className="img-fluid mLogWid" />
                                    </Link>
                                </a>
                                {/* <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="togg">
                                        <i class="fas fa-bars"></i>
                                    </span>
                                </button> */}
                                {/* <div
                                    className="collapse navbar-collapse marg"
                                    id="navbarSupportedContent"
                                > */}
                                <ul className="navbar-nav mr-auto">
                                    <Link to="/">
                                        <li class="nav-item">
                                            Home
                                        </li>
                                    </Link>
                                    <Link to="/domainSearhResult/active/mm">
                                        <li className="nav-item ">
                                            Domains
                                        </li>
                                    </Link>
                                    <Link to="/marketplace">
                                        <li className="nav-item ">
                                            Marketplace
                                        </li>
                                    </Link>
                                    {/* <Link to="/profile">
                                       <li className="nav-item">
                                            About
                                        </li>
                                       </Link> */}
                                    <li class="nav-item dropdown">
                                    </li>
                                </ul>
                                {/* <a href='https://twitter.com/tomipioneers' target="_blank">

                                    <img src="\assets\twitter.svg" alt="img" className="img-fluid  " />
                                </a>
                                <a href='https://discord.com/invite/tomi' className='sIcons mx-3' target="_blank">
                                    <img src="\assets\discord.svg" alt="img" className="img-fluid  " />
                                    <i className='fab fa-discord'></i>
                                </a>
                                <a href='https://t.me/tomipioneers' className='sIcons' target="_blank">
                                    <img src="\assets\telegram.svg" alt="img" className="img-fluid  " />
                                    <i className='fab fa-telegram-plane'></i>
                                </a> */}

                                <a href='/' target="_blank">
                                    <img src="\assets\newlanding-assets\twitter-icon.svg" alt="img" className="img-fluid  " />
                                </a>
                                <a href='/' className='sIcons mx-3' target="_blank">
                                    <img src="\assets\newlanding-assets\discord-icon.svg" alt="img" className="img-fluid  " />
                                    {/* <i className='fab fa-discord'></i> */}
                                </a>
                                <a href='/' className='sIcons' target="_blank">
                                    <img src="\assets\newlanding-assets\telegram-icon.svg" alt="img" className="img-fluid  " />
                                    {/* <i className='fab fa-telegram-plane'></i> */}
                                </a>

                            </nav>
                        </div>
                    </div>
                </div>

            </section>
            <section className="rights">
                <div className="container-fluid p-0">
                    <div className='row'>
                        <div className='col-lg-11 m-auto'>
                            <div className="inner-rightsscsd ">
                                <p className="sdgvsgdv">© 2023 Powered By <span className='common footerdns'><a className='common cdhvdgvdctcv' target='_blank' href="https://quecko.com/">quecko.com</a> </span>
                                    {/* , All Rights Reserved */}
                                </p>
                                <p className='dvsgd'><a target='_blank' style={{color: "#5201BB"}} href="/">Terms of use </a>  |  Privacy policy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer;
