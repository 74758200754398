import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getUNSRegistryContract } from "../../utils/contractHelpers";
import { getEthRegistrarContract } from "../../utils/contractHelpers";
import { getProxyReaderContract } from "../../utils/contractHelpers";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
// import { ethers  } from "ethers";
export const ChildOfId = () => {
  const { account } = useWeb3React()
  const web3 = useWeb3();


  const Mainfunct = useCallback(async (tokenId) => {
    // const tokenAddress = environment.UNSRegistry;
    // const contract = getUNSRegistryContract(tokenAddress, web3);
    const tokenAddress = environment.ethRegistrar;
    const contract = getEthRegistrarContract(tokenAddress, web3);
    // // console.log("here", contract)
    try {
      const name = await contract.methods.tokenURI(tokenId).call();
      // // // console.log('result of the myDomains tokenURi', name)
      return name;
    } catch (err) {
      // // console.log("result of the myDomains error", err)
      return err;
    }


  }, [account]);

  return { Mainfunct: Mainfunct };
};

export default ChildOfId;
