import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../landing/header/Navbar'
import './orderComplete.scss'
function OrderComplete() {
    return (
        <> <Navbar />
            <section className="order-complete">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 mx-auto p-0">
                            <div className="thankyou-div">
                                <img src="\chekout\handshake.png" className='img-fluid' alt="" />
                                <h1>Thank you for your order</h1>
                                <p className='pHH'>We’ve emailed your reciept to <span>xxxxxx@gmail.com</span> </p>
                                <span className='totalDiv'>
                                    <p>Order total</p>
                                    <h6> : US$10.00 <span>Details</span></h6>
                                </span>
                                <button className="w-100 img-btn mt-md-5 mt-3">Go to my domain</button>
                                <button className='w-100 d-block img-btn-outline mt-3'>Search for a new domain</button>
                            </div>
                            <div className="mintNow">
                                <div className="row">
                                    <div className="col-sm-1 text-sm-start text-center my-auto ">
                                        <img src="\chekout\hurrah.png" className='img-fluid w' alt="" />
                                    </div>
                                    <div className="col-sm-9   text-sm-start text-center my-sm-auto mt-4">
                                        <h6>Next step: Mint your domain</h6>
                                        <p>Minting creates your domain on the blockchain and transfers it to you.</p>
                                    </div>
                                    <div className="col-md-2  mt-md-0 mt-4">
                                      <Link to={`/mintDomain`}>  <button className='img-btn bg-light text-dark w-100'><img src="\chekout\mintTick.png" alt="" /> Mint</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderComplete