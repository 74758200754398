import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketPlaceContract } from "../../utils/contractHelpers";
import { gettomiTokenContract } from "../../utils/contractHelpers";
import Allowance from '../dataFetchers/allowance'
const Nftbiding = () => {
  const web3 = useWeb3();
  const { checkAllowance } = Allowance()
  const contractAddress = environment.MarketContract;
  const contract = getMarketPlaceContract(contractAddress, web3);
  const contractAddressApprove = environment.tomiToken;
  const contractApprove = gettomiTokenContract(contractAddressApprove, web3);
  const UserBiding = useCallback(
    async (nBid, tokenId, account) => {
      var gasFunPrice;
      web3.eth.getGasPrice().then((result) => {
        var result2 = parseInt(result) + 3000000000
        gasFunPrice = result2.toString()
      })
      let nd = web3.utils.toWei(nBid.toString(), "ether");
      try {
        let checkAllowanceRes = await checkAllowance(account, environment.MarketContract);
        checkAllowanceRes = web3.utils.toWei(checkAllowanceRes.toString(), "ether");
        let detailsApprove
        if (parseFloat(checkAllowanceRes) > nBid) {
          detailsApprove = true
        } else {
          let amount = '9999999999999999999999999999';
          let tomiAmount = web3.utils.toWei(amount.toString(), "ether");
          const gas = await contractApprove.methods
            .approve(environment.MarketContract, tomiAmount)
            .estimateGas({
              from: account,
            })
          detailsApprove = await contractApprove.methods
            .approve(environment.MarketContract, tomiAmount)
            .send({ gas: gas, gasPrice: gasFunPrice, from: account })
        }

        if (detailsApprove) {
          const gas = await contract.methods.bid(tokenId, nd).
          estimateGas({
              from: account,
              value: '0',
            });
          const details = await contract.methods.bid(tokenId, nd).
            send({
              from: account,
              value: '0',
              gas: gas, gasPrice: gasFunPrice
            });
          return details;
        }

      } catch (error) {
        // // console.log('dsaaaaaaaa', error)
        throw error;
      }
    },
    [contract]
  );
  return { UserBiding: UserBiding };
};

export default Nftbiding;